import React from "react";
import { Heading, Text, Link } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { Divider } from "../components/Divider";
import { useApp } from "../contexts/app.context";
import { Redirect } from "react-router";
import { format } from "date-fns";
import { GoogleMapsLink } from "../components/GoogleMapsLink";
import { Rideshare } from "../components/Rideshare";

export const Plan4LateResponseMadison = () => {
  const { pollingPlace, voterAddress } = useApp();
  if (!pollingPlace) return <Redirect to="/more-help" />;


    return (
    <Layout>
      <Heading size="xl" mb={6} color="red">
        Here is{" "}
        <Text as="span" color="blue">
          your{" "}
        </Text>
        voting plan.
      </Heading>
      <Text mb={3}>
        Vote in person on{" "}
        <Text as="span" fontWeight="bold">
          Election Day
        </Text>
        , November 3, 2020.
      </Text>
      <Text mb={3}>
        You have two options.  You can return your vote-by-mail ballot to your Election Day polling place OR you can abandon that vote-by-mail ballot and simply vote in person.  Don’t do both!
      </Text>
      <Text mb={3}>
        If you abandon your vote-by-mail ballot, we recommend telling an election worker.
      </Text>
      <Divider />
      <Text mb={3}>Your polling place is:</Text>
      <Text>{pollingPlace.pollingLocationName}</Text>
      <GoogleMapsLink
        address={pollingPlace.ppL_Address}
        city={pollingPlace.ppL_City}
        zip={pollingPlace.ppL_PostalCode}
      >
        <Text>{pollingPlace.ppL_Address}</Text>
        <Text>
          {pollingPlace.ppL_City}, {pollingPlace.ppL_PostalCode}
        </Text>
      </GoogleMapsLink>
      <Divider mt={4}/>
      <Text mb={3}>The locations hours are:</Text>
      <Text>{format(new Date(pollingPlace.electionDate), "PPP")}</Text>
      <Text>
        {pollingPlace.startTime} - {pollingPlace.endTime}
      </Text>
      <Divider mt={4}/>
        <Text>
            Remember to bring a photo ID.{" "}
            <Link
                href="https://myvote.wi.gov/en-us/PhotoIDRequired"
                target="_blank"
                color="active"
                textDecoration="underline"
            >
                See which ones are valid
            </Link>
            .
        </Text>
        <Text>
            If you are registering, you need to bring a{" "}
            <Link
                href="https://myvote.wi.gov/en-us/ProofofResidence"
                target="_blank"
                color="active"
                textDecoration="underline"
            >
                proof of residence.{" "}
            </Link>
        </Text>
      <Rideshare />
        <Text> <Text as="span" fontWeight="bold">Curbside Voting:</Text> You can use curbside voting at your polling location on election day or early vote site.  It is available to any voter who is immunocompromised, was exposed to or has symptoms of COVID-19, as well as voters who have a disability.
        </Text>
        <Text mt={3}>
            Call your clerk (<Link target="_blank"
                                   color="active"
                                   textDecoration="underline" href="https://myvote.wi.gov/en-US/MyMunicipalClerk">find your clerk here</Link>) ahead of your trip to arrange or have someone with you go inside the polling place to request curbside assistance.
          Bring your ID, (proof of residence if registering), a pen, and mask up.
      </Text>
    </Layout>
  );
};

import React from "react";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { getNudgeAction, isEarlyVotingAvailable } from "../nudges";
import { pollingPlaceService } from "../services/polling.service";
import { checkStatusService } from "../services/status.service";
import { checkBallotStatusService } from "../services/ballot_status.service";
import { ballotOptionsService } from "../services/ballot_options.service";
import { geocodeService } from "../services/geocode.service";
import emailjs from "emailjs-com";

const AppContext = React.createContext();

export const AppProvider = ({ children }) => {
  const history = useHistory();
  const [voterAddress, setVoterAddress] = useLocalStorage("voterAddress");
  const [voter, setVoter] = useLocalStorage("voter");
  const [pollingPlace, setPollingPlace] = useLocalStorage("pollingPlace");
  const [clerk, setClerk] = useLocalStorage("clerk");
  const [bestVotingPlan, setBestVotingPlan] = useLocalStorage(
    "bestVotingPlan",
    "option1"
  );
  const [userRegistration, setUserRegistration] = useLocalStorage(
    "userRegistration"
  );
  const [ballotStatus, setBallotStatus] = useLocalStorage("ballotStatus");
  const [isRegistered, setIsRegistered] = useLocalStorage("isRegistered");
  const [dropboxes, setDropboxes] = useLocalStorage("dropboxes");
  const [earlyVoting, setEarlyVoting] = useLocalStorage("earlyVoting");

  const clearStorage = () => {
    setVoterAddress();
    setVoter();
    setPollingPlace();
    setClerk();
    setBestVotingPlan();
    setUserRegistration();
    setBallotStatus();
    setIsRegistered();
    setDropboxes();
    setEarlyVoting();
  };

  const DROPBOXES_OPEN_OCT_31 = ["Green Bay", "Wausau", "Marshfield St Francis", "River Falls", "Burlington", "Norway", "Merrimac", "Somerset", "Troy", "Eagle River", "Germantown", "Vernon", "Mazomanie", "Milwaukee", "Madison", "Racine", "Sun Prairie", "Cottage Grove", "Beaver Dam", "McFarland", "Springdale", "Dunn"]
  const DROPBOXES_OPEN_NOV_1 = ["Milwaukee", "Madison", "Racine", "Sun Prairie", "Cottage Grove", "Beaver Dam", "McFarland", "Springdale", "Dunn"]


  const registerBallotStatusUpdates = (formData) => {
    formData.firstName = userRegistration.firstName;
    formData.lastName = userRegistration.lastName;
    formData.birthDate = userRegistration.birthDate;
    emailjs
      .send(
        "service_7kh58fb",
        "template_560s9gv",
        formData,
        "user_W1rlfRwwL0uruCNrxB3Ki"
      )
      .then(
        (result) => {
          history.push("/ballot-status-updates-registered");
        },
        (error) => {
          history.push("/more-help");
        }
      );
  };

  const checkBallotStatus = (formData) => {
    history.push("/checking-status");

    if (fbq) {
      fbq('trackCustom', 'checkBallotStatus');
    }

    //We have to first call checkRegistrationStatus to get the voterID
    checkStatusService.checkStatus(formData).then((response) => {
      const isRegistered =
        response.Success && response.Data.voters.$values.length > 0;
      if (isRegistered) {
        const voter = response.Data.voters.$values[0];
        setVoter(voter);
        setUserRegistration(formData);
        checkBallotStatusService
          .checkStatus(voter.voterID, formData.lastName)
          .then((response) => {
            const ballotStatus = response.Data;
            setBallotStatus(ballotStatus);

            //No Ballot even requested
            if (!ballotStatus || !ballotStatus.absenteeRequestSubmittedDate) {
              history.replace("/ballot-not-requested");
              return;
            }

            //Ballot Returned
            if (ballotStatus.dateBallotReturned) {
              if (!ballotStatus.ballotReturnedRejectedReason) {
                history.replace("/ballot-confirmation");
              } else {
                history.replace("/ballot-rejected");
              }
            } else {
              // All forms of pending
              setBallotStatus(ballotStatus);
              history.replace("/ballot-pending");
            }
          });
      } else {
        setUserRegistration(formData);
        history.replace("/not-registered"); //TODO what happens if they aren't registered here???
        //they think they're registered and claim to have sent their ballot.
        //it's possible our app just couldn't find them. I say we just push them to myvote.wi.gov at this point
      }
    });
  };

  const checkRegistrationStatus = (formData) => {
    //See /docs/myvote.wi.gov.MD for documentation
    history.push("/checking-status");
    setUserRegistration(formData);


    if (fbq) {
      fbq('trackCustom', 'checkRegistrationStatus');
    }

    checkStatusService.checkStatus(formData).then((response) => {
      const isRegistered =
        response.Success && response.Data.voters.$values.length > 0;
      if (isRegistered) {
        const voter = response.Data.voters.$values[0];
        setVoter(voter);
        pollingPlaceService
          .findPlaces({
            addressLine: voter.address,
            city: voter.city,
            zip: voter.postalCode,
            unit: "",
          })
          .then((response) => {
            setPollingPlace(response.Data.pollingPlace);
            setBestVotingPlan(getNudgeAction("registered"));
            setClerk(response.Data.clerk);
            history.replace("/youre-registered");
          });
      } else {
        history.replace("/not-registered");
      }
    });
  };

  const findElectionDayVoting = (formData) => {

    if (fbq) {
      fbq('trackCustom', 'findVotingOptions');
    }

    if (isRegistered) {

    //if is weekend and early voting available, send to early voting
    // const dropboxCity = dropbox_locations[0].city;
    const dropboxCity = formData.city
    const today = new Date(window.localStorage.getItem("date") || new Date());

    if (today.getDate()===31 && today.getMonth()===9 && DROPBOXES_OPEN_OCT_31.includes(dropboxCity)) {
      findEarlyVoting(formData)
      return;
    } else if (today.getDate()===1 && today.getMonth()===10 && DROPBOXES_OPEN_NOV_1.includes(dropboxCity)) {
      findEarlyVoting(formData)
      return;
    }
    }



    pollingPlaceService
      .findPlaces({
        addressLine: formData.addressLine,
        city: formData.city,
        zip: formData.zip,
        unit: formData.unit,
      })
      .then((response) => {
        if (!response.Data.pollingPlace) {
          history.replace("/more-help");
          return;
        }
        setPollingPlace(response.Data.pollingPlace);
        history.replace("/plan-4-election-day");
      });
  };

  const findEarlyVoting = (formData, plan = "/plan-4-early-voting") => {
    history.push("/loading-plan");

    if (fbq) {
      fbq('trackCustom', 'findVotingOptions');
    }

    Promise.all([
      ballotOptionsService.findBallotOptions(
        formData.addressLine,
        formData.city,
        formData.zip
      ),
      geocodeService.geocode(formData.addressLine, formData.city, formData.zip),
    ]).then(([ballotOptions, geocodeResponse]) => {
      const early_voting_locations = ballotOptions.early_vote_locations;
      if (early_voting_locations) {
        if (geocodeResponse.lat && geocodeResponse.lng) {
          geocodeService.addDistances(
            early_voting_locations,
            geocodeResponse.lat,
            geocodeResponse.lng
          );
        }
        setEarlyVoting(early_voting_locations);
      } else {
        setEarlyVoting(undefined);
        history.replace("/more-help");
        return;
      }
      history.replace(plan);
    });
  };


  const checkVotingOptionsWithBallot = (formData) => {
    history.push("/loading-plan");

    if (fbq) {
      fbq('trackCustom', 'findVotingOptions');
    }

    setVoterAddress(formData);

    let plan = getNudgeAction("hasBallot");
    setBestVotingPlan(plan);

    if (plan === "/plan-2") {
      //Need dropbox locations only
      Promise.all([
        ballotOptionsService.findBallotOptions(
          formData.addressLine,
          formData.city,
          formData.zip
        ),
        geocodeService.geocode(
          formData.addressLine,
          formData.city,
          formData.zip
        ),
        pollingPlaceService.findPlaces(formData),
      ]).then(([ballotOptions, geocodeResponse, pollingPlaceResponse]) => {
        const dropbox_locations = ballotOptions.dropbox_locations;
        if (dropbox_locations) {
          if (geocodeResponse.lat && geocodeResponse.lng) {
            geocodeService.addDistances(
              dropbox_locations,
              geocodeResponse.lat,
              geocodeResponse.lng
            );
          }

          // // const dropboxCity = dropbox_locations[0].city;
          // const dropboxCity = formData.city
          // const today = new Date(window.localStorage.getItem("date") || new Date());
          //
          // if (today.getDate()===31 && today.getMonth()===9 && !DROPBOXES_OPEN_OCT_31.includes(dropboxCity)) {
          //   setDropboxes(undefined)
          //   plan = "/plan-4-late-response"
          // } else if (today.getDate()===1 && today.getMonth()===10 && !DROPBOXES_OPEN_NOV_1.includes(dropboxCity)) {
          //   setDropboxes(undefined)
          //   plan = "/plan-4-late-response"
          // } else {
            setDropboxes(dropbox_locations);
          // }

          //Set early voting too because we may want to show more options
          const early_voting_locations = ballotOptions.early_vote_locations;
          if (early_voting_locations) {
            if (geocodeResponse.lat && geocodeResponse.lng) {
              geocodeService.addDistances(
                early_voting_locations,
                geocodeResponse.lat,
                geocodeResponse.lng
              );
            }
            setEarlyVoting(early_voting_locations);
          } else {
            setEarlyVoting(undefined);
          }

          //TODO we should only grab this data if needed
          //set polling place in case they want more options close to election day
          const pollingPlacesFound = pollingPlaceResponse.Data.pollingPlace;
          if (pollingPlacesFound) {
            setPollingPlace(pollingPlaceResponse.Data.pollingPlace);
          }

          history.replace(plan);
        } else {
          setDropboxes(undefined);
          history.replace("/more-help");
        }
      });
      return;
    }

    if (plan.startsWith("/plan-4")) {
      //Only need election-day polling place
      pollingPlaceService
        .findPlaces({
          addressLine: formData.addressLine,
          city: formData.city,
          zip: formData.zip,
          unit: "",
        })
        .then((pollingPlaceResponse) => {
          setPollingPlace(pollingPlaceResponse.Data.pollingPlace);
          setClerk(pollingPlaceResponse.Data.clerk);

          if (
            pollingPlaceResponse.Data.clerk ||
            pollingPlaceResponse.Data.pollingPlace
          ) {
            history.replace(plan);
          } else {
            history.replace("/more-help");
          }
        });
    }
  };

  const checkVotingOptionsWithNoBallot = (formData) => {
    if (fbq) {
      fbq('trackCustom', 'findVotingOptions');
    }

    //need both early-voting and election-day-voting (polling place)
    history.push("/loading-plan");
    pollingPlaceService.findPlaces(formData).then((response) => {
      const pollingPlacesFound = response.Data.clerk;
      if (pollingPlacesFound) {
        // if (isEarlyVotingAvailable("registered")) {
        //   setClerk(response.Data.clerk);
        // } else {
        setPollingPlace(response.Data.pollingPlace);
        // }
        setBestVotingPlan(getNudgeAction("registered"));
      } else {
        history.push("/more-help");
      }
      findEarlyVoting(formData, getNudgeAction("registered"));
    });
  };

  const findPollingPlaces = (formData) => {
    //need both early-voting and election-day-voting (polling place)
    pollingPlaceService.findPlaces(formData).then((response) => {
      const pollingPlacesFound = response.Data.clerk;
      if (pollingPlacesFound) {
        setPollingPlace(response.Data.pollingPlace);
      }
    });
  };

  const createUrl = (base, params) => {
    return Object.entries(params).reduce(
      (accum, [key, value], index) =>
        `${accum}${index === 0 ? "?" : "&"}${key}=${encodeURIComponent(value)}`,
      base
    );
  };

  const createGoogleCalendarUrl = (params) => {

    const now = new Date();
    if (!params.startDate) {
      params.startDate = now.getFullYear().toString() + (now.getMonth()+2).toString() + (now.getDate()+1).toString() + "T140000Z"
      params.endDate = now.getFullYear().toString() + (now.getMonth()+2).toString() + (now.getDate()+1).toString() + "T150000Z"

    }

    return createUrl("https://calendar.google.com/calendar/render", {
      action: params.action,
      dates: `${params.startDate}` + "/" + `${params.endDate}`,
      location: [params.location],
      text: params.text,
      details: params.details,
    });
  };

  return (
    <AppContext.Provider
      value={{
        ballotStatus,
        userRegistration,
        voter,
        voterAddress,
        clerk,
        findEarlyVoting,
        findElectionDayVoting,
        pollingPlace,
        bestVotingPlan,
        dropboxes,
        earlyVoting,
        checkBallotStatus,
        checkRegistrationStatus,
        checkVotingOptionsWithBallot,
        checkVotingOptionsWithNoBallot,
        isRegistered,
        setIsRegistered,
        clearStorage,
        registerBallotStatusUpdates,
        createGoogleCalendarUrl,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => React.useContext(AppContext);

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";

export const BallotStatusUpdatesRegistered = () => {

  return <Layout
    previousPage="/ballot-status-updates"
  >
    <Heading size="xl" mb={6} color="red">
      All set!
    </Heading>
    <Text mb={3}>
      We'll contact you when your status updates. It typically takes 7 - 10 days for a ballot to be confirmed once it has been sent or dropped off.
    </Text>
  </Layout>
};

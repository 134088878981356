import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { Divider } from "../components/Divider";
import { GoodToKnow } from "../components/GoodToKnow";
import { GoogleMapsLink } from "../components/GoogleMapsLink";
import { useApp } from "../contexts/app.context";
import { Rideshare } from "../components/Rideshare";

export const Plan3 = () => {
  const { clerk, bestVotingPlan, createGoogleCalendarUrl } = useApp();
  const link = createGoogleCalendarUrl({
    action: "TEMPLATE",
    location: clerk.mailingAddress,
    text: "Reminder to return your vote-by-mail ballot!",
    details: `Don't forget to mail your ballot to allow plenty of time for it to arrive! Return it early in person at ${clerk.mailingAddress}`,
  });
  return (
    <Layout
      actions={[
        {
          text: "Add a calendar reminder to return my ballot",
          outlink: link,
        },
      ]}
    >
      {bestVotingPlan === window.location.pathname ? (
        <Heading size="xl" mb={6} color="red">
          Here is the best way to return{" "}
          <Text as="span" color="blue">
            your{" "}
          </Text>
          vote-by-mail ballot.
        </Heading>
      ) : (
          <Heading size="xl" mb={6} color="red">
            Here is another option to return{" "}
            <Text as="span" color="blue">
              your{" "}
            </Text>
          ballot.
          </Heading>
        )}
      <Text mb={3}>
        Drop off your completed vote-by-mail ballot{" "}
        <Text as="span" fontWeight="bold">
          early in person
        </Text>
        , starting October 20, 2020.
      </Text>
      <Text>
        You can bring your vote-by-mail ballot or request a new one in person.
        An election worker can help check for errors.
      </Text>
      <Divider />
      <GoodToKnow>
        <Text>The nearest in-person location to your address is:</Text>
        <GoogleMapsLink address={clerk.mailingAddress}>
          <Text>{clerk.mailingAddress}</Text>
        </GoogleMapsLink>
      </GoodToKnow>
      <Rideshare />
    </Layout>
  );
};

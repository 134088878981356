import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { getNudgeAction } from "../nudges";

export const AreYouRegistered = () => {
  const nextPage = getNudgeAction("nonregistered");

  return (
    <Layout
      previousPage="/"
      actions={[
        { text: "Yes", nextPage: "/have-you-received-ballot" },
        { text: "No", nextPage },
        { text: "I'm not sure", nextPage: "/check-registration" },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        Are{" "}
        <Text as="span" color="blue">
          you{" "}
        </Text>
        registered to vote?
      </Heading>
    </Layout>
  );
};

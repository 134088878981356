import React from "react";
import { Heading, Text, Box, Link, Button } from "@chakra-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { Layout } from "../components/Layout";
import { Divider } from "../components/Divider";
import { GoodToKnow } from "../components/GoodToKnow";
import { useApp } from "../contexts/app.context";
import { Rideshare } from "../components/Rideshare";
import { GoogleMapsLink } from "../components/GoogleMapsLink";
import { getNudge } from "../nudges";
import {Redirect} from "react-router";

export const Plan2 = () => {
  const { bestVotingPlan, dropboxes, voterAddress } = useApp();
  const [bestDropbox, ...allDropboxes] = dropboxes.slice(0, 5);
  const { createGoogleCalendarUrl } = useApp();
  const link = createGoogleCalendarUrl({
    action: "TEMPLATE",
    location: bestDropbox.address_line_1,
    text: "Return vote-by-mail ballot to an official ballot drop box",
    details: `Please return your ballot to ${bestDropbox.address_line_1}`,
  });

  if (voterAddress.city === "Madison") {
    return <Redirect to="/plan-4-late-response-madison" />;
  }

  return (
    <Layout
      actions={[
        {
          text: "Add a calendar reminder to mail my ballot",
          outlink: link,
        },
      ]}
    >
      {bestVotingPlan === window.location.pathname ? (
        <Heading size="xl" mb={6} color="red">
          Here is the best way to return{" "}
          <Text as="span" color="blue">
            your{" "}
          </Text>
          vote-by-mail (absentee) ballot.
        </Heading>
      ) : (
          <Heading size="xl" mb={6} color="red">
            Here is another option to return{" "}
            <Text as="span" color="blue">
              your{" "}
            </Text>
          ballot.
          </Heading>
        )}
      <Text mb={3}>
        Drop off your completed vote-by-mail ballot at an{" "}
        <Text as="span" fontWeight="bold">
          election drop-box
        </Text>
        .
      </Text>
      <Divider />

      <GoodToKnow>
        <Text mb={3}>The nearest drop-box to your address is:</Text>
        <Text>{bestDropbox.title}</Text>
        <GoogleMapsLink address={bestDropbox.address_line_1} state="WI">
          <Text>{bestDropbox.address_line_1}</Text>
        </GoogleMapsLink>
        <Text>
          {bestDropbox.dates_hours}
          <sup>*</sup>
        </Text>

        {allDropboxes && allDropboxes.length > 0 && (
          <Box>
            <Divider />
            <Text mb={3}>Other dropbox locations:</Text>
            {allDropboxes.map((dropbox) => (
              <Box key={dropbox.title} mb={3}>
                <Text>{dropbox.title}</Text>
                <GoogleMapsLink address={dropbox.address_line_1} state="WI">
                  <Text>{dropbox.address_line_1}</Text>
                </GoogleMapsLink>
                <Text>
                  {dropbox.dates_hours}
                  <sup>*</sup>
                </Text>
              </Box>
            ))}
          </Box>
        )}

        <Text size="xs" fontSize="xs">
          * Most dropboxes will close early on Election Day - Tuesday, November 3rd. Consequently, we recommend that you return your ballot at least an hour beforehand.  If your municipality concludes drop boxes before Tuesday, November 3rd, you can always bring your vote-by-mail ballot to your Election Day polling place.
        </Text>

        <Divider />

        {getNudge("hasBallot") === "option3" ? (
          <Link as={RouterLink} to="/plan-4-late-response" color="active">
            Alternatively, show me how to vote in person on election day,
            November 3rd.
          </Link>
        ) : (
            <Button as={RouterLink} to="/plan-4-early-voting" color="active">
              Alternatively, show me early voting options which begin October 20.
            </Button>
          )}
      </GoodToKnow>

      <Rideshare />
    </Layout>
  );
};

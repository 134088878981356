import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { LoadingLayout } from "../components/LoadingLayout";

export const LoadingPlan = () => (
  <LoadingLayout>
    <Heading size="xl" mb={6} color="red">
      Putting together the best plan for{" "}
      <Text as="span" color="blue">
        your{" "}
      </Text>
      location.
    </Heading>
  </LoadingLayout>
);

import { wdBallotOptionsService } from "./wd_ballot_options.service";
import { iwvBallotOptionsService } from "./iwv_ballot_options.service";
import { geocodeService } from "./geocode.service";

/**
 * Wrapper service which delegates to other services
 */
class BallotOptionsService {
  constructor() {
    this.isWdEnabled = false;
  }

  buildFailureStatus(message) {
    if (!message) {
      message = "Unexpected Error";
    }
    return {
      Success: false,
      ErrorMessage: message,
    };
  }

  /**
   * Return a dictionary with 2 keys: earl_vote_locations and dropbox_locations
   * @returns {Promise<*>}
   */
  findBallotOptions(street, city, zip) {
    return this.findBallotOptionsIWillVoteOnly(street, city, zip);
  }

  findBallotOptionsGeocodeFirst(street, city, zip) {
    return geocodeService.geocode(street, city, zip, true).then((geocode) => {
      if (!geocode.address_components) {
        return this.findBallotOptionsIWillVoteOnly(street, city, zip);
      }

      let realCity = undefined;
      let realCounty = undefined;

      for (const component of geocode.address_components) {
        if (component.types.includes("administrative_area_level_3")) {
          realCity = component.long_name;
        }
        if (component.types.includes("administrative_area_level_2")) {
          realCounty = component.long_name;
          if (realCounty.endsWith(" County")) {
            realCounty = realCounty.slice(0, -7);
          }
        }
      }

      if (!realCounty || !realCity) {
        return iwvBallotOptionsService
            .findBallotOptions(street, zip)
            .then((response) => {
              return response;
            });
      }

      return wdBallotOptionsService
          .findDropboxesByCounty(realCounty, realCity)
          .then((response) => {
            if (
                response[realCity.toUpperCase()] &&
                response[realCity.toUpperCase()]["early_vote_locations"]
            ) {
              return response[realCity.toUpperCase()];
            }
            //try iwv if no results
            return iwvBallotOptionsService
                .findBallotOptions(street, zip)
                .then((response) => {
                  return response;
                });
          });
    });
  }

  findBallotOptionsIWillVoteOnly(street, city, zip) {
    return iwvBallotOptionsService
        .findBallotOptions(street, zip)
        .then((response) => {
          return response;
        });
  }


}



export const ballotOptionsService = new BallotOptionsService();

import React from "react";
import { Flex, Box, Text } from "@chakra-ui/core";
import { RiLinksLine } from "react-icons/ri";

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

const copyToClipboard = (e) => {
  copyTextToClipboard("https://www.beavoterwi.org");
  e.preventDefault();
}

export const CopyButton = ({ type, children }) => {
  return (
      <Flex
          as="a"
          href={"#"}
          border="2px solid"
          borderColor="gray.300"
          alignItems="center"
          p={4}
          borderRadius={5}
          onClick={ copyToClipboard }
          mb={5}
      >
        <Box
            as={ RiLinksLine }
            mr={4}
            fontSize="2xl"
            color="gray.400"
        />
        <Text color="text">{children}</Text>
      </Flex>
  );
};

let county_lookup = {
  "adell": "sheboygan",
  "allenton": "washington",
  "ashippun": "dodge",
  "belgium": "ozaukee",
  "brookfield": "waukesha",
  "brownsville": "dodge",
  "butler": "waukesha",
  "campbellsport": "fond-du-lac",
  "cascade": "sheboygan",
  "cedarburg": "ozaukee",
  "cedar grove": "sheboygan",
  "chilton": "calumet",
  "cleveland": "manitowoc",
  "clyman": "dodge",
  "colgate": "washington",
  "delafield": "waukesha",
  "eden": "fond-du-lac",
  "elkhart lake": "sheboygan",
  "fredonia": "ozaukee",
  "germantown": "washington",
  "glenbeulah": "sheboygan",
  "grafton": "ozaukee",
  "greenbush": "sheboygan",
  "hartford": "washington",
  "hartland": "waukesha",
  "hingham": "sheboygan",
  "horicon": "dodge",
  "hubertus": "washington",
  "hustisford": "dodge",
  "iron ridge": "dodge",
  "ixonia": "jefferson",
  "jackson": "washington",
  "johnson creek": "jefferson",
  "juneau": "dodge",
  "kewaskum": "washington",
  "kiel": "manitowoc",
  "kohler": "sheboygan",
  "lannon": "waukesha",
  "lebanon": "dodge",
  "lomira": "dodge",
  "malone": "fond-du-lac",
  "mayville": "dodge",
  "menomonee falls": "waukesha",
  "merton": "waukesha",
  "mount calvary": "fond-du-lac",
  "nashotah": "waukesha",
  "neosho": "dodge",
  "newburg": "washington",
  "new holstein": "calumet",
  "newton": "manitowoc",
  "north lake": "waukesha",
  "oakfield": "fond-du-lac",
  "oconomowoc": "waukesha",
  "okauchee": "waukesha",
  "oostburg": "sheboygan",
  "pewaukee": "waukesha",
  "plymouth": "sheboygan",
  "port washington": "ozaukee",
  "random lake": "sheboygan",
  "richfield": "washington",
  "rubicon": "dodge",
  "saint cloud": "fond-du-lac",
  "saukville": "ozaukee",
  "sheboygan": "sheboygan",
  "sheboygan falls": "sheboygan",
  "slinger": "washington",
  "stockbridge": "calumet",
  "sussex": "waukesha",
  "west bend": "washington",
  "theresa": "dodge",
  "mequon": "ozaukee",
  "waldo": "sheboygan",
  "watertown": "dodge",
  "woodland": "dodge",
  "bassett": "kenosha",
  "benet lake": "kenosha",
  "big bend": "waukesha",
  "bristol": "kenosha",
  "burlington": "racine",
  "caledonia": "racine",
  "camp lake": "kenosha",
  "cudahy": "milwaukee",
  "darien": "walworth",
  "delavan": "walworth",
  "dousman": "waukesha",
  "eagle": "waukesha",
  "east troy": "walworth",
  "elkhorn": "walworth",
  "elm grove": "waukesha",
  "fontana": "walworth",
  "franksville": "racine",
  "genesee depot": "waukesha",
  "genoa city": "walworth",
  "greendale": "milwaukee",
  "hales corners": "milwaukee",
  "franklin": "milwaukee",
  "helenville": "jefferson",
  "honey creek": "walworth",
  "kansasville": "racine",
  "kenosha": "kenosha",
  "new berlin": "waukesha",
  "lake geneva": "walworth",
  "lyons": "walworth",
  "mukwonago": "waukesha",
  "muskego": "waukesha",
  "new munster": "kenosha",
  "north prairie": "waukesha",
  "oak creek": "milwaukee",
  "palmyra": "jefferson",
  "pell lake": "walworth",
  "pleasant prairie": "kenosha",
  "powers lake": "kenosha",
  "rochester": "racine",
  "salem": "kenosha",
  "silver lake": "kenosha",
  "somers": "kenosha",
  "south milwaukee": "milwaukee",
  "springfield": "walworth",
  "sturtevant": "racine",
  "sullivan": "jefferson",
  "trevor": "kenosha",
  "twin lakes": "kenosha",
  "union grove": "racine",
  "wales": "waukesha",
  "walworth": "walworth",
  "waterford": "racine",
  "waukesha": "waukesha",
  "whitewater": "walworth",
  "williams bay": "walworth",
  "wilmot": "kenosha",
  "woodworth": "kenosha",
  "zenda": "walworth",
  "milwaukee": "milwaukee",
  "saint francis": "milwaukee",
  "racine": "racine",
  "afton": "rock",
  "albany": "green",
  "arena": "iowa",
  "argyle": "lafayette",
  "avalon": "rock",
  "avoca": "iowa",
  "barneveld": "iowa",
  "belleville": "dane",
  "belmont": "lafayette",
  "beloit": "rock",
  "black earth": "dane",
  "blanchardville": "lafayette",
  "blue mounds": "dane",
  "blue river": "grant",
  "brodhead": "green",
  "brooklyn": "green",
  "browntown": "green",
  "cambridge": "dane",
  "clinton": "rock",
  "cobb": "iowa",
  "cottage grove": "dane",
  "cross plains": "dane",
  "dane": "dane",
  "darlington": "lafayette",
  "deerfield": "dane",
  "deforest": "dane",
  "dodgeville": "iowa",
  "edgerton": "rock",
  "edmund": "iowa",
  "evansville": "rock",
  "footville": "rock",
  "fort atkinson": "jefferson",
  "gotham": "richland",
  "gratiot": "lafayette",
  "hanover": "rock",
  "highland": "iowa",
  "hollandale": "iowa",
  "janesville": "rock",
  "jefferson": "jefferson",
  "juda": "green",
  "lake mills": "jefferson",
  "linden": "iowa",
  "livingston": "grant",
  "lodi": "columbia",
  "lone rock": "richland",
  "lowell": "dodge",
  "mcfarland": "dane",
  "marshall": "dane",
  "mazomanie": "dane",
  "merrimac": "sauk",
  "middleton": "dane",
  "milton": "rock",
  "mineral point": "iowa",
  "monroe": "green",
  "montfort": "grant",
  "monticello": "green",
  "morrisonville": "dane",
  "mount horeb": "dane",
  "muscoda": "grant",
  "new glarus": "green",
  "oregon": "dane",
  "orfordville": "rock",
  "plain": "sauk",
  "prairie du sac": "sauk",
  "reeseville": "dodge",
  "rewey": "iowa",
  "richland center": "richland",
  "ridgeway": "iowa",
  "sauk city": "sauk",
  "sextonville": "richland",
  "sharon": "walworth",
  "shullsburg": "lafayette",
  "south wayne": "lafayette",
  "spring green": "sauk",
  "stoughton": "dane",
  "sun prairie": "dane",
  "verona": "dane",
  "waterloo": "jefferson",
  "waunakee": "dane",
  "windsor": "dane",
  "woodford": "lafayette",
  "madison": "dane",
  "bagley": "grant",
  "beetown": "grant",
  "benton": "lafayette",
  "bloomington": "grant",
  "boscobel": "grant",
  "cassville": "grant",
  "cuba city": "grant",
  "dickeyville": "grant",
  "fennimore": "grant",
  "glen haven": "grant",
  "hazel green": "grant",
  "kieler": "grant",
  "lancaster": "grant",
  "mount hope": "grant",
  "patch grove": "grant",
  "platteville": "grant",
  "potosi": "grant",
  "prairie du chien": "crawford",
  "sinsinawa": "grant",
  "stitzer": "grant",
  "wauzeka": "crawford",
  "woodman": "grant",
  "portage": "columbia",
  "adams": "adams",
  "arlington": "columbia",
  "baraboo": "sauk",
  "brandon": "fond-du-lac",
  "briggsville": "marquette",
  "burnett": "dodge",
  "cambria": "columbia",
  "cazenovia": "richland",
  "columbus": "columbia",
  "dalton": "green-lake",
  "doylestown": "columbia",
  "elroy": "juneau",
  "endeavor": "marquette",
  "fairwater": "fond-du-lac",
  "fall river": "columbia",
  "fox lake": "dodge",
  "friendship": "adams",
  "friesland": "columbia",
  "grand marsh": "adams",
  "hillpoint": "sauk",
  "kingston": "green-lake",
  "lake delton": "sauk",
  "la valle": "sauk",
  "lime ridge": "sauk",
  "loganville": "sauk",
  "lyndon station": "juneau",
  "markesan": "green-lake",
  "marquette": "green-lake",
  "mauston": "juneau",
  "montello": "marquette",
  "new lisbon": "juneau",
  "north freedom": "sauk",
  "oxford": "marquette",
  "packwaukee": "marquette",
  "pardeeville": "columbia",
  "poynette": "columbia",
  "reedsburg": "sauk",
  "rio": "columbia",
  "rock springs": "sauk",
  "union center": "juneau",
  "waupun": "dodge",
  "westfield": "marquette",
  "wisconsin dells": "columbia",
  "wonewoc": "juneau",
  "wyocena": "columbia",
  "amery": "polk",
  "baldwin": "st-croix",
  "beldenville": "pierce",
  "clayton": "polk",
  "clear lake": "polk",
  "cushing": "polk",
  "deer park": "st-croix",
  "dresser": "polk",
  "ellsworth": "pierce",
  "glenwood city": "st-croix",
  "hager city": "pierce",
  "hammond": "st-croix",
  "hudson": "st-croix",
  "new richmond": "st-croix",
  "osceola": "polk",
  "prescott": "pierce",
  "river falls": "pierce",
  "roberts": "st-croix",
  "saint croix falls": "polk",
  "somerset": "st-croix",
  "star prairie": "polk",
  "wilson": "st-croix",
  "woodville": "st-croix",
  "houlton": "st-croix",
  "abrams": "oconto",
  "amberg": "marinette",
  "armstrong creek": "forest",
  "athelstane": "marinette",
  "black creek": "outagamie",
  "bonduel": "shawano",
  "brillion": "calumet",
  "cecil": "shawano",
  "coleman": "marinette",
  "combined locks": "outagamie",
  "crivitz": "marinette",
  "de pere": "brown",
  "dunbar": "marinette",
  "fence": "florence",
  "florence": "florence",
  "forest junction": "calumet",
  "gillett": "oconto",
  "goodman": "marinette",
  "greenleaf": "brown",
  "green valley": "shawano",
  "gresham": "shawano",
  "hilbert": "calumet",
  "kaukauna": "outagamie",
  "freedom": "outagamie",
  "keshena": "menominee",
  "kimberly": "outagamie",
  "krakow": "shawano",
  "lakewood": "oconto",
  "lena": "oconto",
  "little chute": "outagamie",
  "little suamico": "oconto",
  "marinette": "marinette",
  "mountain": "oconto",
  "neopit": "menominee",
  "niagara": "marinette",
  "nichols": "outagamie",
  "oconto": "oconto",
  "oconto falls": "oconto",
  "oneida": "brown",
  "pembine": "marinette",
  "peshtigo": "marinette",
  "porterfield": "marinette",
  "potter": "calumet",
  "pound": "marinette",
  "pulaski": "brown",
  "seymour": "outagamie",
  "shawano": "shawano",
  "sherwood": "calumet",
  "shiocton": "outagamie",
  "sobieski": "oconto",
  "suamico": "brown",
  "suring": "oconto",
  "townsend": "oconto",
  "wausaukee": "marinette",
  "wrightstown": "brown",
  "algoma": "kewaunee",
  "baileys harbor": "door",
  "brussels": "door",
  "casco": "kewaunee",
  "collins": "manitowoc",
  "denmark": "brown",
  "egg harbor": "door",
  "ellison bay": "door",
  "ephraim": "door",
  "fish creek": "door",
  "forestville": "door",
  "francis creek": "manitowoc",
  "kellnersville": "manitowoc",
  "kewaunee": "kewaunee",
  "luxemburg": "kewaunee",
  "manitowoc": "manitowoc",
  "maplewood": "door",
  "maribel": "manitowoc",
  "mishicot": "manitowoc",
  "new franken": "brown",
  "reedsville": "manitowoc",
  "saint nazianz": "manitowoc",
  "sister bay": "door",
  "sturgeon bay": "door",
  "tisch mills": "manitowoc",
  "two rivers": "manitowoc",
  "valders": "manitowoc",
  "washington island": "door",
  "whitelaw": "manitowoc",
  "green bay": "brown",
  "wausau": "marathon",
  "marshfield": "wood",
  "abbotsford": "clark",
  "amherst": "portage",
  "amherst junction": "portage",
  "aniwa": "marathon",
  "antigo": "langlade",
  "arpin": "wood",
  "athens": "marathon",
  "auburndale": "wood",
  "babcock": "wood",
  "birnamwood": "shawano",
  "blenker": "wood",
  "bowler": "shawano",
  "brokaw": "marathon",
  "bryant": "langlade",
  "chili": "clark",
  "colby": "clark",
  "curtiss": "clark",
  "custer": "portage",
  "deerbrook": "langlade",
  "dorchester": "clark",
  "edgar": "marathon",
  "eland": "marathon",
  "elcho": "langlade",
  "elderon": "marathon",
  "elton": "langlade",
  "gilman": "taylor",
  "gleason": "lincoln",
  "granton": "clark",
  "greenwood": "clark",
  "hatley": "marathon",
  "hewitt": "wood",
  "irma": "lincoln",
  "junction city": "portage",
  "loyal": "clark",
  "lublin": "taylor",
  "marathon": "marathon",
  "mattoon": "shawano",
  "medford": "taylor",
  "merrill": "lincoln",
  "milladore": "wood",
  "mosinee": "marathon",
  "neillsville": "clark",
  "nekoosa": "wood",
  "nelsonville": "portage",
  "ogema": "price",
  "owen": "clark",
  "pearson": "langlade",
  "pelican lake": "oneida",
  "phlox": "langlade",
  "pickerel": "langlade",
  "pittsville": "wood",
  "plover": "portage",
  "port edwards": "wood",
  "rib lake": "taylor",
  "ringle": "marathon",
  "rosholt": "portage",
  "rothschild": "marathon",
  "rudolph": "wood",
  "schofield": "marathon",
  "spencer": "marathon",
  "stetsonville": "taylor",
  "stevens point": "portage",
  "stratford": "marathon",
  "summit lake": "langlade",
  "tigerton": "shawano",
  "tomahawk": "lincoln",
  "unity": "marathon",
  "vesper": "wood",
  "westboro": "taylor",
  "white lake": "langlade",
  "willard": "clark",
  "wisconsin rapids": "wood",
  "withee": "clark",
  "wittenberg": "shawano",
  "rhinelander": "oneida",
  "argonne": "forest",
  "boulder junction": "vilas",
  "brantwood": "price",
  "butternut": "ashland",
  "catawba": "price",
  "clam lake": "ashland",
  "conover": "vilas",
  "crandon": "forest",
  "eagle river": "vilas",
  "fifield": "price",
  "gile": "iron",
  "glen flora": "rusk",
  "glidden": "ashland",
  "harshaw": "oneida",
  "hawkins": "rusk",
  "hazelhurst": "oneida",
  "heafford junction": "lincoln",
  "hurley": "iron",
  "iron belt": "iron",
  "kennan": "price",
  "lac du flambeau": "vilas",
  "lake tomahawk": "oneida",
  "land o lakes": "vilas",
  "laona": "forest",
  "long lake": "florence",
  "mc naughton": "oneida",
  "manitowish waters": "vilas",
  "mellen": "ashland",
  "mercer": "iron",
  "minocqua": "oneida",
  "montreal": "iron",
  "park falls": "price",
  "phelps": "vilas",
  "phillips": "price",
  "prentice": "price",
  "presque isle": "vilas",
  "saint germain": "vilas",
  "saxon": "iron",
  "sayner": "vilas",
  "star lake": "vilas",
  "three lakes": "oneida",
  "tony": "rusk",
  "tripoli": "oneida",
  "upson": "iron",
  "wabeno": "forest",
  "woodruff": "oneida",
  "la crosse": "la-crosse",
  "alma": "buffalo",
  "alma center": "jackson",
  "arcadia": "trempealeau",
  "arkdale": "adams",
  "bangor": "la-crosse",
  "black river falls": "jackson",
  "blair": "trempealeau",
  "camp douglas": "juneau",
  "cashton": "monroe",
  "cataract": "monroe",
  "chaseburg": "vernon",
  "cochrane": "buffalo",
  "coon valley": "vernon",
  "de soto": "vernon",
  "dodge": "trempealeau",
  "eastman": "crawford",
  "ettrick": "trempealeau",
  "ferryville": "crawford",
  "fountain city": "buffalo",
  "galesville": "trempealeau",
  "gays mills": "crawford",
  "genoa": "vernon",
  "hillsboro": "vernon",
  "hixton": "jackson",
  "holmen": "la-crosse",
  "hustler": "juneau",
  "kendall": "monroe",
  "la farge": "vernon",
  "mather": "juneau",
  "melrose": "jackson",
  "millston": "jackson",
  "mindoro": "la-crosse",
  "mount sterling": "crawford",
  "necedah": "juneau",
  "norwalk": "monroe",
  "oakdale": "monroe",
  "onalaska": "la-crosse",
  "ontario": "vernon",
  "readstown": "vernon",
  "rockland": "la-crosse",
  "seneca": "crawford",
  "soldiers grove": "crawford",
  "sparta": "monroe",
  "steuben": "crawford",
  "stoddard": "vernon",
  "taylor": "jackson",
  "tomah": "monroe",
  "trempealeau": "trempealeau",
  "tunnel city": "monroe",
  "viola": "richland",
  "viroqua": "vernon",
  "warrens": "monroe",
  "westby": "vernon",
  "west salem": "la-crosse",
  "wilton": "monroe",
  "eau claire": "eau-claire",
  "altoona": "eau-claire",
  "arkansaw": "pepin",
  "augusta": "eau-claire",
  "bay city": "pierce",
  "bloomer": "chippewa",
  "boyceville": "dunn",
  "boyd": "chippewa",
  "cadott": "chippewa",
  "chetek": "barron",
  "chippewa falls": "chippewa",
  "colfax": "dunn",
  "conrath": "rusk",
  "cornell": "chippewa",
  "dallas": "barron",
  "downing": "dunn",
  "downsville": "dunn",
  "durand": "pepin",
  "eau galle": "dunn",
  "eleva": "trempealeau",
  "elk mound": "dunn",
  "elmwood": "pierce",
  "fairchild": "eau-claire",
  "fall creek": "eau-claire",
  "gilmanton": "buffalo",
  "holcombe": "chippewa",
  "humbird": "clark",
  "independence": "trempealeau",
  "jim falls": "chippewa",
  "knapp": "dunn",
  "maiden rock": "pierce",
  "menomonie": "dunn",
  "merrillan": "jackson",
  "mondovi": "buffalo",
  "nelson": "buffalo",
  "new auburn": "chippewa",
  "osseo": "trempealeau",
  "pepin": "pepin",
  "pigeon falls": "trempealeau",
  "plum city": "pierce",
  "prairie farm": "barron",
  "ridgeland": "dunn",
  "rock falls": "dunn",
  "sand creek": "dunn",
  "sheldon": "rusk",
  "spring valley": "pierce",
  "stanley": "chippewa",
  "stockholm": "pepin",
  "strum": "trempealeau",
  "thorp": "clark",
  "wheeler": "dunn",
  "whitehall": "trempealeau",
  "spooner": "washburn",
  "almena": "barron",
  "ashland": "ashland",
  "balsam lake": "polk",
  "barron": "barron",
  "barronett": "barron",
  "bayfield": "bayfield",
  "benoit": "bayfield",
  "birchwood": "washburn",
  "bruce": "rusk",
  "brule": "douglas",
  "cable": "bayfield",
  "cameron": "barron",
  "centuria": "polk",
  "comstock": "barron",
  "cornucopia": "bayfield",
  "couderay": "sawyer",
  "cumberland": "barron",
  "danbury": "burnett",
  "drummond": "bayfield",
  "exeland": "sawyer",
  "foxboro": "douglas",
  "frederic": "polk",
  "gordon": "douglas",
  "grand view": "bayfield",
  "grantsburg": "burnett",
  "haugen": "barron",
  "hawthorne": "douglas",
  "hayward": "sawyer",
  "herbster": "bayfield",
  "hertel": "burnett",
  "high bridge": "ashland",
  "iron river": "bayfield",
  "ladysmith": "rusk",
  "lake nebagamon": "douglas",
  "la pointe": "ashland",
  "luck": "polk",
  "maple": "douglas",
  "marengo": "ashland",
  "mason": "bayfield",
  "mikana": "barron",
  "milltown": "polk",
  "minong": "washburn",
  "odanah": "ashland",
  "ojibwa": "sawyer",
  "poplar": "douglas",
  "port wing": "bayfield",
  "radisson": "sawyer",
  "rice lake": "barron",
  "sarona": "washburn",
  "shell lake": "washburn",
  "siren": "burnett",
  "solon springs": "douglas",
  "south range": "douglas",
  "springbrook": "washburn",
  "stone lake": "sawyer",
  "superior": "douglas",
  "trego": "washburn",
  "turtle lake": "barron",
  "wascott": "douglas",
  "washburn": "bayfield",
  "webster": "burnett",
  "weyerhaeuser": "rusk",
  "winter": "sawyer",
  "oshkosh": "winnebago",
  "almond": "portage",
  "appleton": "outagamie",
  "bancroft": "portage",
  "bear creek": "outagamie",
  "berlin": "green-lake",
  "big falls": "waupaca",
  "butte des morts": "winnebago",
  "caroline": "shawano",
  "clintonville": "waupaca",
  "coloma": "waushara",
  "dale": "outagamie",
  "eldorado": "fond-du-lac",
  "embarrass": "waupaca",
  "fond-du-lac": "fond-du-lac",
  "fremont": "waupaca",
  "green-lake": "green-lake",
  "greenville": "outagamie",
  "hancock": "waushara",
  "hortonville": "outagamie",
  "iola": "waupaca",
  "king": "waupaca",
  "larsen": "winnebago",
  "leopolis": "shawano",
  "manawa": "waupaca",
  "marion": "waupaca",
  "menasha": "winnebago",
  "neenah": "winnebago",
  "neshkoro": "marquette",
  "new london": "waupaca",
  "ogdensburg": "waupaca",
  "omro": "winnebago",
  "pickett": "winnebago",
  "pine river": "waushara",
  "plainfield": "waushara",
  "poy sippi": "waushara",
  "princeton": "green-lake",
  "readfield": "waupaca",
  "redgranite": "waushara",
  "ripon": "fond-du-lac",
  "rosendale": "fond-du-lac",
  "saxeville": "waushara",
  "scandinavia": "waupaca",
  "tilleda": "shawano",
  "van dyne": "fond-du-lac",
  "waukau": "winnebago",
  "waupaca": "waupaca",
  "wautoma": "waushara",
  "weyauwega": "waupaca",
  "wild rose": "waushara",
  "winnebago": "winnebago",
  "winneconne": "winnebago"
}

export default county_lookup;

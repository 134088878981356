class GeocodeService {
  constructor() {
    this.mockData = {};
  }

  buildFailureStatus(message) {
    if (!message) {
      message = "Unexpected Error";
    }
    return {
      Success: false,
      ErrorMessage: message,
    };
  }

  geocode(street, city, zip, full = undefined) {
    let address = `${street} ${city}, ${zip}`;

    let url = new URL(
      "https://4rhb9e1m5j.execute-api.us-east-1.amazonaws.com/Prod/geocode"
    );
    url.searchParams.append("address", address);
    if (full) {
      url.searchParams.append("full", true);
    }
    console.log("about to fetch " + url);

    return fetch(url)
      .then((response) => response.json())
      .catch((e) => this.buildFailureStatus(e));
  }

  /**
   * For an array of objects, points, that contain keys lat & lon, and an origin that contains lat & lon,
   * add distance to each item in points from origin.
   */
  addDistances(points, lat, lon) {
    for (const point of points) {
      point.distance = this.miles_apart(lat, lon, point.lat, point.lon);
    }
    points.sort(function (a, b) {
      if (a.distance > b.distance) {
        return 1;
      }
      if (a.distance < b.distance) {
        return -1;
      }
      return 0;
    });
  }

  miles_apart(lat1, lon1, lat2, lon2) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      return dist;
    }
  }
}

export const geocodeService = new GeocodeService();

import { Box, Input } from "@chakra-ui/core";
import React from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";

let debug;
const queryParam = new URLSearchParams(window.location.search).get("debug");

if (typeof queryParam === "string") {
  if (queryParam === "on") {
    debug = true;
    window.localStorage.setItem("debug", true);
  } else {
    debug = false;
    window.localStorage.removeItem("debug");
  }
} else {
  if (JSON.parse(window.localStorage.getItem("debug"))) {
    debug = true;
    window.localStorage.setItem("debug", true);
  }
}

export const QADatePicker = () => {
  const [date, setDate] = useLocalStorage("date", new Date());

  if (!debug) return null;

  return (
    <Box
      backgroundColor="blue"
      position="fixed"
      top={0}
      right={0}
      width={250}
      color="white"
      p={2}
      opacity={0.5}
      _hover={{ opacity: 1 }}
    >
      <a href="/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z" />
        </svg>
      </a>
      Mock date picker
      <Input
        color="black"
        type="date"
        defaultValue={date}
        value={date}
        onChange={(event) => setDate(event.target.value)}
      />
    </Box>
  );
};

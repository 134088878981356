import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { useApp } from "../contexts/app.context";
import { Redirect } from "react-router";
import { getNudgeAction } from "../nudges";

export const YoureRegistered = () => {
  const { voter, setIsRegistered } = useApp();
  if (!voter) return <Redirect to="/more-help" />;
  setIsRegistered(true);
  const findAVotingPlanPath = getNudgeAction("registered");
  return (
    <Layout
      actions={[
        {
          text: "Let's find a voting plan",
          nextPage: findAVotingPlanPath,
        },
        {
          text: "That's not me",
          nextPage: "/recheck-registration",
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        Nice. It looks like{" "}
        <Text as="span" color="blue">
          you{" "}
        </Text>
        are registered.
      </Heading>

      <Text color="red" fontWeight="bold" fontSize="sm">
        Your name
      </Text>
      <Text mb={4}>{voter.voterName}</Text>

      <Text color="red" fontWeight="bold" fontSize="sm">
        Your birthdate
      </Text>
      <Text mb={4}>
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "long",
          timeZone: "UTC",
        }).format(new Date(voter.dateOfBirth))}
      </Text>

      <Text color="red" fontWeight="bold" fontSize="sm">
        Your address
      </Text>
      <Text mb={4}>
        {voter.address}
        <br />
        {voter.city}, {voter.state}
      </Text>
    </Layout>
  );
};

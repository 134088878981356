import React from "react";
import { Box, Link, Text, Image, Icon } from "@chakra-ui/core";
import { Divider } from "./Divider";
import { FaHome } from "react-icons/fa";

export const Footer = () => (
  <>
    <Divider mt={100} />
    <Box as="footer" align="center" pt={2} pb={[64, 64, 6]}>
      <Text color="active">
        Questions or need help?{" "}
        <Link textDecoration="underline" href="tel:6082852141">Call or text 608-285-2141</Link>.
          <Box mt={2}>
              <Link textDecoration="underline" href="/">
                  <Box as={FaHome} display="inline-block" mr={3}/>
                  Return Home
              </Link>.
          </Box>

          <Box mt={4} align="center">
              <Link target="_blank" href="https://abetterwisconsininstitute.org/">
                  <Image width={250} src="https://abetterwisconsininstitute.org/wp-content/uploads/abwt-institute-logo.png"/>
              </Link>
          </Box>
      </Text>

    </Box>
  </>
);

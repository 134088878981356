import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";

export const BallotPending = () => {

  return <Layout
    previousPage="/check-ballot-status"
    actions={[
      { text: "Update me by text", nextPage: "/ballot-status-updates" },
      { text: "Update me by email", nextPage: "/ballot-status-updates" }
    ]}
  >
    <Heading size="xl" mb={6} color="red">
      The state has not received{" "}
      <Text as="span" color="blue">
        your{" "}
      </Text>
      ballot yet.
    </Heading>
    <Text mb={3}>
      It typically takes 7 - 10 days for a ballot to be confirmed once it has been sent or dropped off.
    </Text>
    <Text>
        Would you like us to send you an update once it's confirmed?
    </Text>
  </Layout>
};

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { Divider } from "../components/Divider";
import { GoodToKnow } from "../components/GoodToKnow";
import { useApp } from "../contexts/app.context";

export const Plan1 = () => {
  const { createGoogleCalendarUrl } = useApp();
  const link = createGoogleCalendarUrl({
    action: "TEMPLATE",
    startDate: `20201018T140000Z`,
    endDate: `20201018T141500Z`,
    location: "",
    text: "Reminder to return your vote-by-mail ballot!",
    details:
      "Don't forget to mail your ballot to allow plenty of time for it to arrive! Return it by mailing from your home, dropping it in an official USPS mail drop box, or directly to your post office.",
  });

  return (
    <Layout
      actions={[
        {
          text: "Add a calendar reminder to return my ballot",
          outlink: link,
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        Here is the best way to return{" "}
        <Text as="span" color="blue">
          your{" "}
        </Text>
        vote-by-mail (absentee) ballot.
      </Heading>
      <Text mb={3}>
        Send your ballot through the{" "}
        <Text as="span" fontWeight="bold">
          US Postal Service
        </Text>
        .
      </Text>
      <Divider />
      <GoodToKnow />
    </Layout>
  );
};

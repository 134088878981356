import React from "react";
import { Link } from "@chakra-ui/core";

export const GoogleMapsLink = ({ address, city, zip, state, children }) => (
  <Link
    href={`https://www.google.com/maps/dir//${encodeURIComponent(
      `${address}, ${city || ""} ${state || ""} ${zip || ""}`.trim()
    )}`}
    target="_blank"
    color="active"
    textDecoration="underline"
  >
    {children}
  </Link>
);

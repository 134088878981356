import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { getNudge } from "../nudges";

export const BallotNotRequested = () => {
  let nextPage = "/how-do-you-want-to-vote";

  switch (getNudge("registered")) {
    case "option1":
      break;
    case "option2":
      nextPage = "/find-early-voting";
      break;
    case "option3":
      nextPage = "/find-election-day-voting";
  }

  return (
    <Layout
      previousPage="/check-ballot-status"
      actions={[
        {
          text: "Show me other ways to vote (Recommended)",
          nextPage: nextPage,
        },
        {
          text: "Take me to myvote.wi.gov to request a ballot",
          outlink: "https://myvote.wi.gov/en-us/TrackMyBallot",
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        It doesn't look like you requested a ballot
      </Heading>
      <Text mb={3}>
        We checked myvote.wi.gov, which reported that you have not requested an
        absentee ballot.
      </Text>
    </Layout>
  );
};

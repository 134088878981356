import React from "react";
import { Box } from "@chakra-ui/core";

export const BaseButton = ({ children, ...rest }) => (
  <Box
    {...rest}
    border="2px solid"
    borderColor="blue"
    borderRadius={6}
    display="block"
    py={3}
    textAlign="center"
    mb={3}
    paddingLeft={3}
    paddingRight={3}
    bg="white"
    boxShadow="large"
    color="red"
    fontWeight="bold"
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="auto"
    lineHeight={1.5}
  >
    {children}
  </Box>
);

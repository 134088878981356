import { ThemeContext } from "@chakra-ui/core";
import { useContext, useEffect, useState } from "react";

export function useMedia(screenSize) {
  const { breakpoints } = useContext(ThemeContext);
  const queries = Object.keys(breakpoints).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: `(min-width: ${breakpoints[key]})`,
    }),
    {}
  );
  const query = queries[screenSize] || "(min-width: 0px)";

  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    const listener = () => {
      setMatches(media.matches);
    };

    media.addListener(listener);

    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}

import React from "react";
import { Box, Text, Link } from "@chakra-ui/core";
import { Divider } from "./Divider";

export const GoodToKnow = ({ children }) => (
  <Box>
    {children ? (
      <Box mb={4}>
        {children}
        <Divider />
      </Box>
    ) : null}
    <Text mb={3}>
      Historically, 2% of vote-by-mail ballots are disqualified. That's
      estimated to be 30,000 invalid ballots for the upcoming election.
    </Text>
    <Text mb={3}>
      Follow these rules to make sure your vote-by-mail ballot is valid:
    </Text>
    <Box as="ul" pl={6}>
      <Box as="li" mb={3}>
          If you make a mistake, do not erase it or cross it out. Instead, contact your local clerk's office for advice and a possible replacement ballot. Your vote-by-mail ballot will tell you how to contact them.
      </Box>
      <Box as="li" mb={3}>
        Get a witness to sign your ballot. Make sure to include THEIR address,
        too, even if it's the same as yours.{" "}
        <Link target="_blank" color="active" href="https://elections.wi.gov/node/6991">
          What is a witness?
        </Link>
      </Box>
      <Box as="li" mb={3}>
        Sign the envelope.
      </Box>
    </Box>
  </Box>
);

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { FormLayout } from "../components/FormLayout";
import { useApp } from "../contexts/app.context";
import { Redirect } from "react-router";

export const RecheckRegistration = () => {
  const { userRegistration, checkRegistrationStatus } = useApp();

  if (!userRegistration) return <Redirect to="/more-help" />;

  return (
    <FormLayout
      previousPage="/youre-registered"
      showSubmitInitial
      submitText="Recheck my status"
      onSubmit={(values) => {
        checkRegistrationStatus(values);
      }}
      fields={[
        {
          id: "firstName",
          type: "text",
          label: "What's your first name?",
          value: userRegistration.firstName,
        },
        {
          id: "lastName",
          type: "text",
          label: "What's your last name?",
          value: userRegistration.lastName,
        },
        {
          id: "birthDate",
          type: "date",
          label: "What's your birthdate?",
          value: userRegistration.birthDate,
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        That&apos;s okay. Please verify{" "}
        <Text as="span" color="blue">
          your{" "}
        </Text>
        details.
      </Heading>
      <Text mb={4}>
        Update any mistakes below, and we can check your status again.
      </Text>
      <Text color="red" fontWeight="bold" fontSize="sm" mb={3}>
        The details you entered
      </Text>
    </FormLayout>
  );
};

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { Redirect } from 'react-router';

export const BallotStatusUnknown = () => {
  setTimeout(() => { window.location.href = 'https://myvote.wi.gov/en-US/TrackMyBallot' }, 10000);
  return (
    <Layout>
      <Heading size="xl" mb={6} color="red">
        We're having difficulty confirming the status of
      <Text as="span" color="blue">
          your{" "}
        </Text>
      ballot.
    </Heading>
      <Text mb={3}>
        We recommend to track the status of your vote-by-mail ballot directly at My Vote WI. We will send you there in ten seconds.
    </Text>
    </Layout>
  );
}

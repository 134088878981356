import React from "react";
import { Heading, Text, Link } from "@chakra-ui/core";
import { Layout } from "../components/Layout";

export const RejectedBallot = () => (
  <Layout
    actions={[
      {
        text: "Take me to myvote.wi.gov to learn how to fix your ballot",
        outlink: "https://myvote.wi.gov/en-us/TrackMyBallot",
      },
    ]}
  >
    <Text mb={3}>
      We checked your ballot and learned that it has been rejected. We recommend
      that you visit https://myvote.wi.gov, or contact your municipal clerk to
      take action immediately.
    </Text>
  </Layout>
);

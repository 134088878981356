import county_lookup from './county_lookup'

class WDBallotOptionsService {
  constructor() {
    this.mockData = {}
  }

  buildFailureStatus(message) {
    if (!message) {
      message = "Unexpected Error";
    }
    return {
      Success: false,
      ErrorMessage: message,
    };
  }

  findDropboxesByCity(city) {

    let county = county_lookup[city.toLowerCase()];
    if (!county) {
      return Promise.resolve(this.buildFailureStatus("Could not match city to county"));
    }

    return this.findDropboxesByCounty(county, city)
  }


  findDropboxesByCounty(county, city) {
    const url =  new URL("https://4rhb9e1m5j.execute-api.us-east-1.amazonaws.com/Prod/findBallotDropboxes")
    url.searchParams.append("county", county)
    url.searchParams.append("city", city)

    console.log("about to fetch " + url);

    return fetch(url)
        .then((response) => {
          return response.json();
        })
      .catch((e) => this.buildFailureStatus(e));
  }
}

export const wdBallotOptionsService = new WDBallotOptionsService();

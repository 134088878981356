import React from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

export const GoogleAnalytics = () => {
  const history = useHistory();

  React.useEffect(() => {
    ReactGA.initialize("UA-179946676-1");

    return history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });
  }, []);

  return null;
};

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { FormLayout } from "../components/FormLayout";
import { useApp } from "../contexts/app.context";

export const BallotStatusUpdates = () => {
  const { findEarlyVoting, isRegistered, registerBallotStatusUpdates } = useApp();

  return (
    <FormLayout
      previousPage="/ballot-pending"
      submitText="Update me when my ballot is received"
      onSubmit={(values) => {
        registerBallotStatusUpdates(values);
      }}
      fields={[
        {
          id: "phoneNumber",
          type: "text",
          label: "What's your phone number?",
          optional: true
        },
        {
          id: "email",
          type: "text",
          label: "What is your email address?",
          optional: true
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        Let us keep{" "}
        <Text as="span" color="blue">
          you{" "}
        </Text>
        up to date on your ballot status.
      </Heading>
    </FormLayout>
  );
};

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { LoadingLayout } from "../components/LoadingLayout";

export const CheckingStatus = () => {
  return (
    <LoadingLayout>
      <Heading size="xl" mb={6} color="red">
        We&apos;re checking{" "}
        <Text as="span" color="blue">
          your{" "}
        </Text>
        status with the state.
      </Heading>
    </LoadingLayout>
  );
};

import React from "react";
import {
  FormControl,
  FormLabel,
  Container,
  Button,
  Box,
  Input,
} from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";
import { Formik, Form, Field } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { Stripes } from "./Stripes";
import { BaseButton } from "./BaseButton";
import { useMedia } from "../hooks/useMediaQuery";
import { StripesHeader } from "./StripesHeader";

const MotionBox = motion.custom(Box);

export const FormLayout = ({
  submitText,
  fields,
  actions,
  showSubmitInitial,
  onSubmit,
  previousPage,
  children,
}) => {
  const isMediumSize = useMedia("md");

  /**
   * this allows you to paste in tab-separated data and have it split between the inputs
   */
  const onPaste = (e, setFieldValue) => {
    const pastedText = e.clipboardData.getData("text");
    if (pastedText && pastedText.includes("\t")) {
      const splits = pastedText.split("\t");
      const pasteWithTabs = document.querySelectorAll(".pasteWithTabs");

      let targetFound = false;
      pasteWithTabs.forEach((input) => {
        if (input === e.target) {
          targetFound = true;
        }
        if (targetFound && splits.length > 0) {
          const name = input.getAttribute("name");
          if (input.type === "date") {
            const date = new Date(splits.shift().trim());
            setFieldValue(name, date.toISOString().substring(0, 10));
          } else {
            setFieldValue(name, splits.shift().trim());
          }
        }
      });
      e.stopPropagation();
      e.preventDefault();
      e.target.blur();
    }
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={fields.reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur.value || "" }),
          {}
        )}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ handleSubmit, isValid, dirty, setFieldValue }) => (
          <React.Fragment>
            {isMediumSize && <StripesHeader />}
            <Container pt={5}>
              {/*{previousPage && (*/}
              {/*  <Box as={Link} p={0} pt={4} display="block" to={previousPage}>*/}
              {/*    <Box as={FiArrowLeft} color="text" fontSize="2xl"></Box>*/}
              {/*  </Box>*/}
              {/*)}*/}
              <Box py={3}>
                {children}
                <Form>
                  {fields.map((field) => (
                    <Box key={field.id} mb={6}>
                      <FormControl id={field.id}>
                        <FormLabel>{field.label}</FormLabel>
                        <Field
                          type={field.type}
                          name={field.id}
                          as={Input}
                          validate={(value) =>
                            field.optional ? false : !value
                          }
                          placeholder={field.placeholder}
                          onPaste={(e) => onPaste(e, setFieldValue)}
                          className="pasteWithTabs"
                        />
                      </FormControl>
                    </Box>
                  ))}
                  { !isMediumSize && (<Box height={158}/>) }
                  {isMediumSize && (
                    <AnimatePresence>
                      {(showSubmitInitial || dirty) && isValid && (
                        <MotionBox
                          initial={{ opacity: 0, y: 15 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 15 }}
                        >
                          <BaseButton as={Button} onClick={handleSubmit}>
                            {submitText}
                          </BaseButton>
                          {actions &&
                            actions.map((action) => (
                              <BaseButton
                                as={Link}
                                to={action.nextPage}
                                key={action.text}
                              >
                                {action.text}
                              </BaseButton>
                            ))}
                        </MotionBox>
                      )}
                    </AnimatePresence>
                  )}
                </Form>
              </Box>
            </Container>
            {!isMediumSize && (
              <AnimatePresence>
                {(showSubmitInitial || dirty) && isValid && (
                  <MotionBox
                    position="fixed"
                    pt={4}
                    bottom={0}
                    width="100%"
                    bg="background"
                    boxShadow="large"
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    exit={{ y: "100%" }}
                  >
                    <Box position="absolute" left={0} top={0}>
                      <Stripes />
                    </Box>
                    <Container zIndex={10} position="relative">
                      <BaseButton as={Button} onClick={handleSubmit}>
                        {submitText}
                      </BaseButton>
                      {actions &&
                        actions.map((action) => (
                          <BaseButton
                            as={Link}
                            to={action.nextPage}
                            key={action.text}
                          >
                            {action.text}
                          </BaseButton>
                        ))}
                    </Container>
                  </MotionBox>
                )}
              </AnimatePresence>
            )}
          </React.Fragment>
        )}
      </Formik>
    </React.Fragment>
  );
};

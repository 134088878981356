import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { getNudge } from "../nudges";

export const GetRegistered = () => (
  <Layout
    previousPage="/are-you-registered"
    actions={[
      getNudge("nonregistered") === "option1" && {
        text: "Register online today",
        outlink: "https://myvote.wi.gov/en-us/RegisterToVote",
      },
      {
        text: "Register and vote in person, beginning Oct 20th",
        nextPage: "/find-early-voting",
      },
      {
        text: "Register and vote in person on Election Day, Nov 3rd",
        nextPage: "/find-election-day-voting",
      },
    ].filter(Boolean)}
  >
    <Heading size="xl" mb={6} color="red">
      Okay. We can help{" "}
      <Text as="span" color="blue">
        you{" "}
      </Text>
      get registered.
    </Heading>
  </Layout>
);

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { getNudge } from "../nudges";
import {Redirect} from "react-router";

export const HowDoYouWantToVote = () => {

  if (getNudge("registered") !== "option1") {
    return <Redirect to="/find-early-voting" />;
  }
  return (
    <Layout
      actions={[
        getNudge("registered") === "option1" && {
          text: "Use a vote-by-mail ballot",
          outlink: "https://myvote.wi.gov/en-US/VoteAbsentee",
        },
        { text: "Vote in person beginning October 20th", nextPage: "/find-early-voting" },
      ].filter(Boolean)}
    >
      <Heading size="xl" mb={6} color="red">
        Do{" "}
        <Text as="span" color="blue">
          you{" "}
        </Text>
        want to use a vote-by-mail ballot or vote in person?
      </Heading>

      <Text mb={3}>
        If you choose to vote by mail, we'll connect you to Wisconsin’s ballot
        request site, and help you make a voting plan.
      </Text>

      <Text>If you prefer to vote early in person, early voting begins October 20.</Text>
    </Layout>
  );
};

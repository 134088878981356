const hours_replacement = {
  "10/20-11/1 M, T, W, Th, F, Sat, Sun from 8:00 AM to 7:00 PM\nSat & Sun are 10am to 4pm":
    "Tue, Oct 20 - Sun Nov 1: \nMon-Fri: 8am - 7pm \nSat-Sun: 8am - 4pm",
  "10/20-11/1 M, T, W, Th, F, Sat, Sun from 1:00 PM to 7:00 PM\nM & Tu are 7am-1pm; Sa & Sun are 10am-4pm":
    "Tue, Oct 20 - Sun Nov 1: \nMon & Tue: 7am - 1pm \nWed - Fri: 1pm - 7pm\nSat & Sun: 10am - 4pm",
  "10/20-10/30 M, T, W, Th, F from 10:00 AM to 3:00 PM":
    "Tue, Oct 21 - Fri Oct 30: \nMon - Fri: 10am - 3pm \nSat & Sun - CLOSED",
  "Appointment only! Request via 414-286-5995 or kdzapat@milwaukee.gov":
    "By appointment only. Appointment requests will be considered after October 20th. Please note requests are honored based  on the voter's inability to vote at any of the other sites during the scheduled voting hours.  Please email kdzapat@milwaukee.gov to request an appointment.",
  "10/20-10/30 M, T, W, Th, F, Sat, Sun from 8:00 AM to 4:30 PM\n10/24 & 10/30 8:00 AM to 4:00 PM, 10/25 12:00 PM to 4:00 PM":
    "Tue, Oct 20 - Sun Nov 1: \nMon - Fri: 8am - 4:30pm \nFri, Oct 30: 8am - 5pm\nSat, Oct 24: 8am - 4pm\nSun, Oct 25: 12pm - 4pm\nSat, Oct 31: 1pm - 5pm\nSun, Nov 1: 1pm - 5pm\nKenosha will also offer a drive through option at City Hall (625 52nd St) during these dates and times",
  "10/20-11/1 M, T, W, Th, F, Sat from 8:00:00 AM to 5:00:00 PM\n10/24 and 10/31 11AM-3PM; open Sun 11/1 10AM-4PM":
    "Tue, Oct 20 - Sun Nov 1: \nMon - Fri: 8am - 5pm \nSat: 11am - 3pm\nSun, Oct 25 - CLOSED \nSun, Nov 1 - 10am - 4pm",
  "10/20-10/30 M, T, W, Th, F, from 4:30:00 PM to 8:00:00 PM\n":
    "Tue, Oct 20 - Sun Nov 1: \nMon - Fri: 4:30pm - 8pm \nSat & Sun: CLOSED",
  "10/20-10/30 M, T, W, Th, F from 1:00:00 PM to 4:00:00 PM":
    "Tue, Oct 20 - Sun Nov 1: \nMon - Fri: 1pm - 4pm \nSat & Sun: CLOSED",
  "10/20-10/30 M, T, W, Th, F, from 9:00:00 AM to 12:00:00 PM":
    "Tue, Oct 20 - Sun Nov 1: \nMon - Fri: 9am - 12pm \nSat & Sun: CLOSED",
  "10/20-10/30 M, T, W, Th, F, from 9:00:00 AM to 4:00:00 PM":
    "Tue, Oct 20 - Sun Nov 1: \nMon - Fri: 9am - 4pm \nSat & Sun: CLOSED",
  "10/20-10/31 M, T, W, Th, F, Sat from 8:00:00 AM to 4:30:00 PM\n10/21 & 10/22 8-6:30; 10/24 10-4\n":
    "Tues,Oct 20 - Fri, Oct 30\n8am - 4:30pm\n10/21 and 10/22: 8am - 6:30pm\n10/24 and 10/31: 10am-4pm\nSun: CLOSED",
  "10/20-10/31 M, T, W, Th, F, Sat from 12:00 PM to 6:00 PM\nSaturdays open 1-4\n":
    "Tue, Oct 20 - Fri, Oct 30:\nMon - Fri: 12pm to 6pm\n10/24 and 10/31: 1pm to 4pm\nSundays: CLOSED ",
  "10/26-10/30 M, T, W, Th, F from 10:00 AM to 2:00 PM":
    "Mon, Oct 26 - Fri, Oct 26:\n10am to 2pm",
  "10/20-10/30 M, T, W, Th, F, Sat, Sun from 11:00 AM to 6:00 PM":
    "Tue, Oct 20 - Fri, Oct 30:\n11am to 6pm",
  "10/20-11/1 M, T, W, Th, F, Sat, Sun from 8:00 AM to 7:00 PM\nSa and Su at 1-5pm":
    "Tue, Oct 20 - Sun, Nov 1:\nMon - Fri: 8am to 7pm\nSat and Sun: 1pm to 5pm",
  "10/20-10/30 M, T, W, Th, F from 10:00 AM to 6:00 PM":
    "Tue, Oct 20 - Fri, Oct 30:\nMon - Fri: 10am to 6pm\nSat and Sun: CLOSED",
  "10/20-10/31 M, T, W, Th, F, Sat from 12:00 PM to 6:00 PM\n(By appointment only (call 266-4601 24 hours in advance to make an appointment))\n":
    "Tue, Oct 20 - Fri, Oct 30:\nMon - Fri: 12pm to 6pm\n10/24 and 10/31: 1pm to 4pm\nSundays: CLOSED  \nBy appointment only (call 266-4601 24 hours in advance to make an appointment)",

  "10/20-10/30 M, T, W, Th, F,  from 9:00:00 AM to 12:00:00 PM":
      "Tue, Oct 20 - Fri, Oct 30:\nMon - Fri: 9am to 12pm\nSat and Sun: CLOSED",
  "10/20-10/30 M, T, W, Th, F, from 4:30:00 PM to 8:00:00 PM":
      "Tue, Oct 20 - Fri, Oct 30:\nMon - Fri: 4:30pm to 8pm\nSat and Sun: CLOSED",
  "10/20-10/30 M, T, W, Th, F from 1:00:00 AM to 4:00:00 PM":
      "Tue, Oct 20 - Fri, Oct 30:\nMon - Fri: 1pm to 4pm\nSat and Sun: CLOSED",
  "10/20-10/30 M, T, W, Th, F,  from 9:00:00 AM to 4:00:00 PM":
      "Tue, Oct 20 - Fri, Oct 30:\nMon - Fri: 9am to 4pm\nSat and Sun: CLOSED",
  "10/20-10/30 M, T, W, Th, F, Sat from 8:30:00 AM to 4:00:00 PM":
      "Tue, Oct 20 - Fri, Oct 30:\nMon - Sat: 8:30am to 4pm\nSun: CLOSED",
  "10/20-10/31 M, T, W, Th, F, Sat from 12:00 PM to 6:00 PM\nSaturdays open 1-4":
      "Tue, Oct 20 - Fri, Oct 30:\nMon - Fri: 12pm to 6pm\n10/24 and 10/31: 1pm to 4pm\nSundays: CLOSED\nMon, Nov 2 - CLOSED",
  "10/20-10/31 M, T, W, Th, F, Sat from 8:00:00 AM to 4:30:00 PM\n10/21 & 10/22 8-6:30; 10/24 10-4":
      "Tues,Oct 20 - Fri, Oct 30\n8am - 4:30pm\nExceptions: \n10/21 and 10/22: 8am - 6:30pm\n10/24 and 10/31: 10am-4pm\nSun: CLOSED",



  "10/20-11/1 M, T, W, Th, F from 8:00 AM to 7:00 PM, Sat & Sun: 10am to 4pm": `Sat Oct 31- 10am - 4pm 
  Sun Nov 1 - 10am - 4pm

  Monday, Nov 2 - CLOSED`,
  "10/20-11/1 M & Tu 7am-1pm, Wed-Fri 1pm-7pm, Sa & Sun 10am-4pm": `Sat Oct 31- 10am - 4pm 
  Sun Nov 1 - 10am - 4pm

  Monday, Nov 2 - CLOSED`,
  "By appointment only. Appointment requests will be considered after October 20th. Please note requests are honored based  on the voter's inability to vote at any of the other sites during the scheduled voting hours.  Please email kdzapat@milwaukee.gov to request an appointment.": `By appointment only. Appointment requests will be considered after October 20th. Please note requests are honored based  on the voter's inability to vote at any of the other sites during the scheduled voting hours.  Please email kdzapat@milwaukee.gov to request an appointment.`,
  "10/26-10/30 8AM to 5PM; 10/31 11AM-3PM, 11/1 10AM-4PM": `Sat Oct 31 - 11am - 3pm 
Sun, Nov 1 - 10am - 4pm

Mon, Nov 2 - CLOSED`,
};

const hours_overrides = {
  "Milwaukee - Milwaukee Area Technical College": `Sat Oct 31- 11am - 2pm 
Sun Nov 1 - CLOSED

Monday, Nov 2 - CLOSED`,
  "City Of Kenosha - Kenosha Municipal Building": `Early voting has concluded.  If you have a vote-by-mail ballot, you can still use a drop box.  If not, you can still vote on election day, Tuesday November 3rtd.  

Find your polling place here: (link to https://voter-sherpa.netlify.app/find-election-day-voting)`,

  "City Of Columbus - City Hall": "Tue, Oct 20 - Thurs, Oct 29: \n" +
      "Mon and Fri: 8:30am to 11:30pm & 1:30pm to 4:30pm \n" +
      "Tues - Thurs: 8:30am to 11:30pm & 1:30pm to 7:30pm \n" +
      "Fri, Oct 30: 8:30am to 5pm \n" +
      "Sat and Sun: CLOSED \n" +
      "Mon, Nov 2: CLOSED \n",

  "City Of Milwaukee - University Of Wisconsin-Milwaukee, Student Union": `Early voting has concluded at this location,  Please use one of the other voting locations`,

  "City of Racine - Humble Park Community Center": "Early voting has concluded at this location.  You can still vote early at Festival Hall",
  "City Of Racine - Gateway Technical College": "Early voting has concluded at this location.  You can still vote early at Festival Hall",
  "City Of Racine - Gilmore Middle School": "Early voting has concluded at this location.  You can still vote early at Festival Hall",
  "City Of Racine - Tyler Domer Community Center": "Early voting has concluded at this location.  You can still vote early at Festival Hall",
  "City Of Racine - Starbuck Middle School": "Early voting has concluded at this location.  You can still vote early at Festival Hall",
  "City Of Racine - Dr. John Bryant Community Center": "Early voting has concluded at this location.  You can still vote early at Festival Hall",
  "City Of Racine - Roosevelt Elementary School": "Early voting has concluded at this location.  You can still vote early at Festival Hall",
  "City Of Racine - Cesar Chavez Community Center": "Early voting has concluded at this location.  You can still vote early at Festival Hall",

  "City Of Madison - Alicia Ashman Library": `Early voting has concluded at this location and there are no special appointments available. 
You can still vote early at the City Clerk location on Saturday and Sunday:`,

  "City Of Madison - Uw-Madison Union South": `Early voting has concluded at this location.  
You can still vote early at the City Clerk location on Saturday and Sunday`,
  "City Of Madison - Uw-Madison Student Activity Center": `Early voting has concluded at this location.  
You can still vote early at the City Clerk location on Saturday and Sunday`,
  "City Of Madison - Uw-Madison Memorial Union": `Early voting has concluded at this location.  
You can still vote early at the City Clerk location on Saturday and Sunday`,
  "City Of Madison - Edgewood College outside Predolin Hall": `Early voting has concluded at this location.  
You can still vote early at the City Clerk location on Saturday and Sunday`,
  "City Of Madison - Madison College South Campus": `Early voting has concluded at this location.  
You can still vote early at the City Clerk location on Saturday and Sunday`,
  "City Of Madison - Madison College Truax Campus": `Early voting has concluded at this location.  
You can still vote early at the City Clerk location on Saturday and Sunday`,

  "City Of Madison - Goodman South Madison Library": `Sat Oct 31 - 1pm - 4pm 
Sun Nov 1: CLOSED, (early voting available at city clerk location)`,
  "City Of Madison - Sequoya Library": `Sat Oct 31 - 1pm - 4pm 
Sun Nov 1: CLOSED, (early voting available at city clerk location)`,
  "City Of Madison - Hawthorne Library": `Sat Oct 31 - 1pm - 4pm 
Sun Nov 1: CLOSED, (early voting available at city clerk location)`,
  "City Of Madison - Meadowridge Library": `Sat Oct 31 - 1pm - 4pm 
Sun Nov 1: CLOSED, (early voting available at city clerk location)`,
  "City Of Madison - Pinney Library": `Sat Oct 31 - 1pm - 4pm 
Sun Nov 1: CLOSED, (early voting available at city clerk location)`,
  "City Of Madison - Lakeview Library": `Sat Oct 31 - 1pm - 4pm 
Sun Nov 1: CLOSED, (early voting available at city clerk location)`,




  "City Of Madison - City Clerk'S Office": `Sat Oct 31 - 1pm - 5pm 
Sun Nov 1 - 1pm - 5pm`,
  "City Of Madison - City Clerk's Office": `Sat Oct 31 - 1pm - 5pm 
Sun Nov 1 - 1pm - 5pm`,

}

export function cleanUpHoursForAllOptions(ballot_options) {
  if (ballot_options) {
    for (const ev of ballot_options) {
      cleanUpHours(ev);
    }
    return ballot_options;
  }
}

export function cleanUpHours(option) {
  let full_text = option.dates_hours;
  if (option.schedule_exceptions) {
    full_text = full_text + "\n" + option.schedule_exceptions;
  }
  if (hours_replacement[full_text]) {
    option.dates_hours = hours_replacement[full_text];
    option.schedule_exceptions = undefined;
  }
  if (hours_overrides[option.title]) {
    option.dates_hours = hours_overrides[option.title]
    option.schedule_exceptions = undefined;
  }
}

import React from "react";
import { ChakraProvider } from "@chakra-ui/core";
import defaultTheme from "@chakra-ui/theme";
import { render } from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./pages/Home";
import { AreYouRegistered } from "./pages/AreYouRegistered";
import { BallotConfirmation } from "./pages/BallotConfirmation";
import { BallotPending } from "./pages/BallotPending";
import { BallotStatusUnknown } from "./pages/BallotStatusUnknown";
import { HaveYouReceivedBallot } from "./pages/HaveYouReceivedBallot";
import { GetRegistered } from "./pages/GetRegistered";
import { CheckBallotStatus } from "./pages/CheckBallotStatus";
import { CheckRegistration } from "./pages/CheckRegistration";
import { AppProvider } from "./contexts/app.context";
import { FindVotingOptionsWithBallot } from "./pages/FindVotingOptionsWithBallot";
import { FindVotingOptionsWithNoBallot } from "./pages/FindVotingOptionsWithNoBallot";
import { HaveYouSubmittedBallot } from "./pages/HaveYouSubmittedBallot";
import { LoadingPlan } from "./pages/LoadingPlan";
import { CheckingStatus } from "./pages/CheckingStatus";
import { Share } from "./pages/Share";
import { YoureRegistered } from "./pages/YoureRegistered";
import { RecheckRegistration } from "./pages/RecheckRegistration";
import { NotRegistered } from "./pages/NotRegistered";
import { Plan1 } from "./pages/Plan1";
import { Plan2 } from "./pages/Plan2";
import { Plan3 } from "./pages/Plan3";
import { Plan4 } from "./pages/Plan4";
import { HowDoYouWantToVote } from "./pages/HowDoYouWantToVote";
import { Plan4LateResponse } from "./pages/Plan4LateResponse";
import { Plan4LateResponseMadison } from "./pages/Plan4LateResponseMadison";
import { Plan4EarlyVoting } from "./pages/Plan4EarlyVoting";
import { Plan4ElectionDay } from "./pages/Plan4ElectionDay";
import { FindEarlyVoting } from "./pages/FindEarlyVoting";
import { MoreHelp } from "./pages/MoreHelp";
import { FindElectionDayVoting } from "./pages/FindElectionDayVoting";
import { GoogleAnalytics } from "./components/GoogleAnalytics";
import { QADatePicker } from "./components/QADatePicker";
import { RejectedBallot } from "./pages/RejectedBallot";
import { BallotNotRequested } from "./pages/BallotNotRequested";
import { BallotStatusUpdates } from "./pages/BallotStatusUpdates";
import { BallotStatusUpdatesRegistered } from "./pages/BallotStatusUpdatesRegistered";
import {Redirect} from "react-router";

const theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    red: "#c6534d",
    white: "#ffffff",
    blue: "#245888",
    text: "#000000",
    // active: "#6996BD",
    active: "#245888",
    border: "#A2C0D7",
    background: "white",
  },
  shadows: {
    large: "0px 5px 10px rgba(0, 0, 0, 0.1)",
  },
};

const App = () => {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Router>
        <AppProvider>
          <QADatePicker />
          <GoogleAnalytics />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route
              path="/are-you-registered"
              exact
              component={AreYouRegistered}
            />
            <Route path="/get-registered" exact component={GetRegistered} />
            <Route
              path="/find-voting-options-with-no-ballot"
              exact
              component={FindVotingOptionsWithNoBallot}
            />
            <Route
              path="/find-voting-options-with-ballot"
              exact
              component={FindVotingOptionsWithBallot}
            />
            <Route
              path="/recheck-registration"
              exact
              component={RecheckRegistration}
            />
            <Route
              path="/check-registration"
              exact
              component={CheckRegistration}
            />
            <Route
              path="/have-you-submitted-ballot"
              exact
              component={HaveYouSubmittedBallot}
            />
            <Route
              path="/have-you-received-ballot"
              exact
              component={HaveYouReceivedBallot}
            />
            <Route
              path="/check-ballot-status"
              exact
              component={CheckBallotStatus}
            />
            <Route path="/loading-plan" exact component={LoadingPlan} />
            <Route path="/checking-status" exact component={CheckingStatus} />
            <Route
              path="/ballot-confirmation"
              exact
              component={BallotConfirmation}
            />
            <Route path="/ballot-pending" exact component={BallotPending} />
            <Route path="/ballot-rejected" exact component={RejectedBallot} />
            <Route
              path="/ballot-not-requested"
              exact
              component={BallotNotRequested}
            />
            <Route
              path="/ballot-status-unknown"
              exact
              component={BallotStatusUnknown}
            />
            <Route
              path="/ballot-status-updates"
              exact
              component={BallotStatusUpdates}
            />
            <Route
              path="/ballot-status-updates-registered"
              exact
              component={BallotStatusUpdatesRegistered}
            />
            <Route path="/share" exact component={Share} />
            <Route path="/youre-registered" exact component={YoureRegistered} />
            <Route path="/not-registered" exact component={NotRegistered} />
            <Route path="/plan-1" exact component={Plan1} />
            <Route path="/plan-2" exact component={Plan2} />
            <Route path="/plan-3" exact component={Plan3} />
            <Route path="/plan-4" exact component={Plan4} />
            <Route
              path="/plan-4-early-voting"
              exact
              component={Plan4EarlyVoting}
            />
            <Route
              path="/plan-4-election-day"
              exact
              component={Plan4ElectionDay}
            />
            <Route
              path="/plan-4-late-response"
              exact
              component={Plan4LateResponse}
            />
            <Route
              path="/plan-4-late-response-madison"
              exact
              component={Plan4LateResponseMadison}
            />
            <Route
              path="/find-election-day-voting"
              exact
              component={FindElectionDayVoting}
            />
            <Route
              path="/find-early-voting"
              exact
              component={FindEarlyVoting}
            />
            <Route path="/more-help" exact component={MoreHelp} />
            <Route
              path="/how-do-you-want-to-vote"
              exact
              component={HowDoYouWantToVote}
            />
            <Route component={Home} />
          </Switch>
        </AppProvider>
      </Router>
    </ChakraProvider>
  );
};

render(<App />, document.querySelector("#root"));

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { ShareButton } from "../components/ShareButton";
import { CopyButton } from "../components/CopyButton";

export const Share = () => (
  <Layout>
    <Heading size="xl" mb={6} color="red">
      Tell{" "}
      <Text as="span" color="blue">
        your{" "}
      </Text>
      friends and family their vote matters.
    </Heading>
    <ShareButton type="facebook">Message your uncle.</ShareButton>
    <ShareButton type="twitter">Tweet fellow packer fans.</ShareButton>
    <CopyButton type="copy">Copy and paste this link anywhere.</CopyButton>
  </Layout>
);

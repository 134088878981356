import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import {useApp} from "../contexts/app.context";

export const BallotConfirmation = () => {

  const {ballotStatus} = useApp();

  return <Layout
      previousPage="/check-ballot-status"
      actions={[
        {text: "Share with your friends", nextPage: "/share"},
        {
          text: "Sign up as poll worker",
          outlink: "https://myvote.wi.gov/en-us/PollWorker",
        },
      ]}
  >
    <Heading size="xl" mb={6} color="red">
      <Text as="span" color="blue">
        Your{" "}
      </Text>
      ballot has been received.

    </Heading>
    <Text mb={3}>
      Great news! You have done everything you can to ensure that your vote
      counts.
    </Text>
    <Text>
      Share this site with other voters or volunteer to help with the election
    </Text>
  </Layout>
};

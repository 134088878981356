import { isAfter, isBefore, isEqual } from "date-fns";

// Dates at midnight in WI
const NOV_1 = 1604206800000;
const NOV_2 = 1604296800000;
const NOV_3 = 1604383200000;
const NOV_4 = 1604469600000;


const nudges = {
  registered: {
    option1: {
      range: [0, 0],
      action: "/how-do-you-want-to-vote",
    },
    option2: {
      range: [0, 0],
      action: "/how-do-you-want-to-vote",
    },
    option3: {
      range: [0, NOV_3],
      action: "/plan-4-late-response",
    },
    option4: {
      range: [NOV_3, NOV_4],
      action: "/plan-4-late-response",
    },
  },
  nonregistered: {
    option1: {
      range: [0, 0],
      action: "/get-registered",
    },
    option2: {
      range: [0, 0],
      action: "/get-registered",
    },
    option3: {
      range: [0, NOV_3],
      action: "/find-election-day-voting",
    },
    option4: {
      range: [NOV_3, NOV_4],
      action: "/find-election-day-voting",
    },
  },
  hasBallot: {
    option1: {
      range: [0, 0],
      action: "/plan-1",
    },
    option2: {
      range: [0, 0],
      action: "/plan-2",
    },
    option3: {
      // TODO: fix date logic
      range: [0, NOV_4],
      action: "/plan-2",
    },
    option4: {
      // TODO: fix timezone logic
      range: [NOV_4, NOV_4],
      action: "/plan-4-late-response",
    },
  },
};

export const getNudge = (status) => {
  const today = new Date(window.localStorage.getItem("date") || new Date());
  const now = today.getTime();
  const nudge = nudges[status];

  if (now > nudge.option1.range[0] && now <= nudge.option1.range[1]) {
    return "option1";
  }
  if (now > nudge.option2.range[0] && now <= nudge.option2.range[1]) {
    return "option2";
  }
  if (now > nudge.option3.range[0] && now <= nudge.option3.range[1]) {
    return "option3";
  }
  return "option4";

};

export const isEarlyVotingAvailable = (status) =>
  ["option1", "option2"].includes(getNudge(status));

export const getNudgeAction = (status) =>
  nudges[status][getNudge(status)].action;

import React from "react";
import { Heading, Text, Link } from "@chakra-ui/core";
import { Layout } from "../components/Layout";

export const MoreHelp = () => (
  <Layout actions={[{ text: "Take me to myvote.wi.gov", outlink: "https://myvote.wi.gov" }]}>
    <Text mb={3}>
      We had trouble confirming your details. Please call{" "}
      <Link href="tel:6082852141" color="active">
        608-285-2141
      </Link>{" "}
      or visit{" "}
      <Link href="https://myvote.wi.gov" color="active" target="_blank">
        myvote.wi.gov
      </Link>{" "}
      for help.
    </Text>
  </Layout>
);

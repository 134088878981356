import React from "react";
import { Container, Box, Grid } from "@chakra-ui/core";
import { AiFillStar } from "react-icons/ai";
import { StripesHeader } from "./StripesHeader";
import { useMedia } from "../hooks/useMediaQuery";

const x = 18;
const y = 32;

export const LoadingLayout = ({ children }) => {
  const [on, setOn] = React.useState(false);
  const isMediumSize = useMedia("md");

  React.useEffect(() => {
    const interval = setInterval(() => {
      setOn((on) => !on);
    }, 350);

    return () => clearInterval(interval);
  }, []);

  return (
    <React.Fragment>
      {isMediumSize && <StripesHeader />}
      <Container pt={5}>
        <Box py={3}>{children}</Box>
        <Box width={x * 7} position="relative" mx="auto">
          <Box
            as={AiFillStar}
            color={on ? "red" : "blue"}
            fontSize="3xl"
            position="absolute"
            right={0}
          ></Box>
          <Box
            as={AiFillStar}
            color={on ? "blue" : "red"}
            fontSize="3xl"
            position="absolute"
            right={x * 1 + "px"}
            top={y * 1 + "px"}
          ></Box>
          <Box
            as={AiFillStar}
            color={on ? "red" : "blue"}
            fontSize="3xl"
            position="absolute"
            right={x * 2 + "px"}
            top={y * 2 + "px"}
          ></Box>
          <Box
            as={AiFillStar}
            color={on ? "blue" : "red"}
            fontSize="3xl"
            position="absolute"
            right={x * 3 + "px"}
            top={y * 3 + "px"}
          ></Box>
          <Box
            as={AiFillStar}
            color={on ? "red" : "blue"}
            fontSize="3xl"
            position="absolute"
            right={x * 4 + "px"}
            top={y * 4 + "px"}
          ></Box>
          <Box
            as={AiFillStar}
            color={on ? "blue" : "red"}
            fontSize="3xl"
            position="absolute"
            right={x * 5 + "px"}
            top={y * 3 + "px"}
          ></Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

import { Box, Link, Text } from "@chakra-ui/core";
import { FaLyft, FaUber } from "react-icons/fa";
import React from "react";

export const Rideshare = () => (
  <Box mt={8}>
    <Link
      href="tel:414-246-1823"
      target="_blank"
      p={5}
      border="1px solid"
      borderColor="active"
      display="block"
      borderRadius={4}
      textAlign="center"
      mb={3}
    >
      <Box as={FaLyft} fontSize="4xl" display="inline" color="pink.500" />
      <Text>If you are having trouble with transportation, we are here to help. Call 414-246-1823.</Text>
    </Link>
  </Box>
);

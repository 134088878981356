import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { FormLayout } from "../components/FormLayout";
import { useApp } from "../contexts/app.context";

export const FindVotingOptionsWithNoBallot = () => {
  const { checkVotingOptionsWithNoBallot } = useApp();

  const urlParams = new URLSearchParams(window.location.search);

  return (
    <FormLayout
      previousPage="/have-you-submitted-ballot"
      submitText="Help me vote"
      onSubmit={(values) => {
        checkVotingOptionsWithNoBallot(values);
      }}
      fields={[
        {
          id: "addressLine",
          type: "text",
          label: "What's your street address?",
          value: urlParams.get("addressLine")
        },
        {
          id: "city",
          type: "text",
          label: "What city do you live in?",
          value: urlParams.get("city")
        },
        {
          id: "zip",
          type: "text",
          label: "What is your ZIP code?",
          value: urlParams.get("zipcode")
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        That&apos;s okay. Let&apos;s find the best option for{" "}
        <Text as="span" color="blue">
          you{" "}
        </Text>
        to still vote.
      </Heading>
    </FormLayout>
  );
};

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { getNudge } from "../nudges";
import { useApp } from "../contexts/app.context";

export const HaveYouReceivedBallot = () => {
  const { setIsRegistered } = useApp();
  setIsRegistered(true);
  let nextPage = "/how-do-you-want-to-vote";

  switch (getNudge("registered")) {
    case "option1":
      break;
    case "option2":
      nextPage = "/find-election-day-voting";
      break;
    case "option3":
      nextPage = "/find-election-day-voting";
  }

  return (
    <Layout
      previousPage="/are-you-registered"
      actions={[
        { text: "Yes", nextPage: "/have-you-submitted-ballot" },
        {
          text: "No",
          nextPage: "/find-election-day-voting",
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        Have{" "}
        <Text as="span" color="blue">
          you{" "}
        </Text>
        already received{" "}
        <Text as="span" color="blue">
          your{" "}
        </Text>
        vote-by-mail (absentee) ballot?
      </Heading>
    </Layout>
  );
};

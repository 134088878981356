import React from "react";
import { Box } from "@chakra-ui/core";

export const Divider = ({mt}) => (
  <Box
    as="hr"
    borderTop="1px solid"
    borderBottom="1px solid"
    borderTopColor="blue"
    borderBottomColor="red"
    height="2px"
    my={6}
    mt={ mt }
  ></Box>
);

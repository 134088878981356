import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { getNudge } from "../nudges";

export const HaveYouSubmittedBallot = () => (
  <Layout
    previousPage="/have-you-received-ballot"
    actions={[
      {
        text: "Yes",
        nextPage: "/check-ballot-status",
      },
      {
        text: "No",
        nextPage:
          getNudge("registered") === "option1"
            ? "/plan-1"
            : "/find-voting-options-with-ballot",
      },
    ]}
  >
    <Heading size="xl" mb={6} color="red">
      Have{" "}
      <Text as="span" color="blue">
        you{" "}
      </Text>
      already submitted{" "}
      <Text as="span" color="blue">
        your{" "}
      </Text>
      vote-by-mail ballot?
    </Heading>
  </Layout>
);

import React from "react";
import { Heading, Text, Box } from "@chakra-ui/core";
import { FormLayout } from "../components/FormLayout";
import { useApp } from "../contexts/app.context";
import {getNudgeAction} from "../nudges";

export const NotRegistered = () => {
  const { userRegistration, checkRegistrationStatus } = useApp();
  const nextPage = getNudgeAction("nonregistered");

  return (
    <FormLayout
      submitText="Recheck my status"
      showSubmitInitial
      actions={[
        {
          text: "Tell me easy ways to register",
          nextPage: nextPage,
        },
      ]}
      onSubmit={(values) => {
        checkRegistrationStatus(values);
      }}
      fields={[
        {
          id: "firstName",
          type: "text",
          label: "What's your first name?",
          value: userRegistration.firstName,
        },
        {
          id: "lastName",
          type: "text",
          label: "What's your last name?",
          value: userRegistration.lastName,
        },
        {
          id: "birthDate",
          type: "date",
          label: "What's your birthdate?",
          value: userRegistration.birthDate,
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        We couldn&apos;t find{" "}
        <Text as="span" color="blue">
          your{" "}
        </Text>
        details.
      </Heading>

      <Text mb={4}>
        You might not be registered yet, or there might be a mistake with the
        details you entered. Please double-check your details below.
      </Text>

      <Text mb={4}>
        If the details are incorrect, please update them here and try again.
      </Text>

      <Text mb={4}>
        If the details are correct, try another version of your name or remove
        special characters, and try again.
      </Text>

      <Text mb={4}>
        You can also continue to get information on how to register.
      </Text>

      <Text color="red" fontWeight="bold" fontSize="sm" mb={3}>
        The details you entered
      </Text>

    </FormLayout>
  );
};

import React from "react";
import { Box, Heading, Text, Link } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { Divider } from "../components/Divider";
import { useApp } from "../contexts/app.context";
import { Redirect } from "react-router";
import { GoogleMapsLink } from "../components/GoogleMapsLink";
import { Rideshare } from "../components/Rideshare";
import {
  cleanUpHours,
  cleanUpHoursForAllOptions,
} from "../services/hours_replacement";

export const Plan4EarlyVoting = () => {
  const { earlyVoting, isRegistered } = useApp();
  const [topLocation, ...otherLocations] = earlyVoting;

  cleanUpHoursForAllOptions(otherLocations);
  cleanUpHours(topLocation);

  if (!earlyVoting) return <Redirect to="/more-help" />;
  return (
    <Layout>
      <Heading size="xl" mb={6} color="red">
        Here is the best way for you{" "}
        <Text as="span" color="blue">
        {isRegistered ? "to vote." : "to register and vote."}
        </Text>
      </Heading>
      <Text mb={3}>
        {isRegistered ? "Vote " : "Instantly register and vote "}
        <Text as="span" fontWeight="bold">
          early in person
        </Text>
        , starting October 20, 2020.
      </Text>
      <Text mb={3}>
        Below are early voting sites affiliated with your home address.
      </Text>
      <Divider />
      <Text mb={3}>The nearest early voting location to your address is:</Text>
      <Text fontWeight="bold">{topLocation.title}</Text>
      <GoogleMapsLink
        address={topLocation.address_line_1 + ", " + topLocation.zip}
      >
        {topLocation.address_line_1 + ", " + topLocation.zip}
      </GoogleMapsLink>
      <Text whiteSpace="pre-wrap">{topLocation.dates_hours}</Text>
      {topLocation.schedule_exceptions && (
        <Text>({topLocation.schedule_exceptions})</Text>
      )}
      {otherLocations && otherLocations.length > 0 && (
        <Box>
          <Divider />
          <Text mb={3}>Other early voting locations:</Text>
          {otherLocations.slice(0, 50).map((location) => (
            <Box key={location.title} mb={3}>
              <Text>{location.title}</Text>
              <GoogleMapsLink
                address={location.address_line_1 + ", " + location.zip}
              >
                {location.address_line_1 + ", " + location.zip}
              </GoogleMapsLink>
              <Text whiteSpace="pre-wrap">{location.dates_hours}</Text>
              {location.schedule_exceptions && (
                <Text>({location.schedule_exceptions})</Text>
              )}
            </Box>
          ))}
        </Box>
      )}
      <Divider />
      <Text>
        Remember to bring a photo ID.{" "}
        <Link
          href="https://myvote.wi.gov/en-us/PhotoIDRequired"
          target="_blank"
          color="active"
          textDecoration="underline"
        >
          See which ones are valid
        </Link>
        .
      </Text>
      <Text>
        If you are registering, you need to bring a{" "}
        <Link
          href="https://myvote.wi.gov/en-us/ProofofResidence"
          target="_blank"
          color="active"
          textDecoration="underline"
        >
          proof of residence.{" "}
        </Link>
      </Text>
      <Rideshare />
    </Layout>
  );
};

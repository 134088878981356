import { Box, Flex } from "@chakra-ui/core";
import React from "react";
import { Stripes } from "./Stripes";

export const StripesHeader = () => {
  return (
    <Flex
      justifyContent="space-between"
      position="absolute"
      width="100%"
      top={0}
      left={0}
      zIndex={-100}
    >
      <Stripes />
      <Stripes flip />
    </Flex>
  );
};

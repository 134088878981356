import React from "react";
import { Container, Box } from "@chakra-ui/core";
import { Link } from "react-router-dom";
import { FiArrowLeft, FiLink } from "react-icons/fi";
import { Stripes } from "./Stripes";
import { StripesHeader } from "./StripesHeader";
import { BaseButton } from "./BaseButton";
import { useMedia } from "../hooks/useMediaQuery";
import { Footer } from "./Footer";
import { motion, AnimatePresence } from "framer-motion";

const MotionBox = motion.custom(Box);

const ButtonList = ({ actions }) => {
  return (
    <React.Fragment>
      {actions.map((action) =>
        action.nextPage ? (
          <BaseButton as={Link} to={action.nextPage} key={action.text}>
            {action.text}
          </BaseButton>
        ) : (
          <BaseButton
            as="a"
            href={action.outlink}
            target="_blank"
            key={action.text}
          >
            {action.text} <Box as={FiLink} color="red" ml={4} />
          </BaseButton>
        )
      )}
    </React.Fragment>
  );
};

export const Layout = ({ actions, previousPage, children }) => {
  const isMediumSize = useMedia("md");
  return (
    <React.Fragment>
      {isMediumSize && <StripesHeader />}
      <Container pt={5}>
        <Box py={3}>{children}</Box>
        {isMediumSize && actions && (
            <MotionBox
              initial={{ opacity: 0, y: 15 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 15 }}
            >
              <Box mt={4}>
                <ButtonList actions={actions} />
              </Box>
            </MotionBox>
        )}
        <Footer />
      </Container>
      {!isMediumSize && actions && (
          <MotionBox
            position="fixed"
            pt={4}
            bottom={0}
            width="100%"
            bg="background"
            boxShadow="large"
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
          >
            <Box
              position="fixed"
              pt={4}
              bottom={0}
              width="100%"
              bg="background"
              boxShadow="large"
              borderTop="1px solid #DBE7F1"
            >
              <Box position="absolute" left={0} top={0}>
                <Stripes />
              </Box>
              <Container zIndex={10} position="relative">
                <ButtonList actions={actions} />
              </Container>
            </Box>
          </MotionBox>
      )}
    </React.Fragment>
  );
};

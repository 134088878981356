import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { FormLayout } from "../components/FormLayout";
import { useApp } from "../contexts/app.context";

export const CheckBallotStatus = () => {
  const { checkBallotStatus } = useApp();

  return (
    <FormLayout
      previousPage="/have-you-submitted-ballot"
      submitText="Check my status"
      onSubmit={(values) => {
        checkBallotStatus(values);
      }}
      fields={[
        {
          id: "firstName",
          type: "text",
          label: "What's your first name?",
        },
        {
          id: "lastName",
          type: "text",
          label: "What's your last name?",
        },
        {
          id: "birthDate",
          type: "date",
          label: "What's your birthdate?",
          placeholder: "mm/dd/yyy",
        },
      ]}
    >
      <Heading size="xl" mb={6} color="red">
        Let&apos;s check the status of{" "}
        <Text as="span" color="blue">
          your{" "}
        </Text>
        ballot.
      </Heading>
    </FormLayout>
  );
};

import React from "react";
import { Heading, Text } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { useApp } from "../contexts/app.context";

export const Home = () => {
  const { clearStorage } = useApp();

  React.useEffect(() => {
    clearStorage();
  }, []);

  return (
    <Layout
      actions={[{ text: "Let's get started", nextPage: "/are-you-registered" }]}
    >
      <Heading size="xl" mb={6} color="red">
        We can help you be a {" "}
        <Text as="span" color="blue">
          voter
        </Text>
        .
      </Heading>
      <Text mb={3}>
        Need to register? Vote absentee? Vote early? Vote Nov. 3? Whatever works for you, we can help you find the answer and Be a Voter.
      </Text>
    </Layout>
  );
};

import React from "react";
import { Flex, Box, Text } from "@chakra-ui/core";
import { RiFacebookCircleFill, RiTwitterFill } from "react-icons/ri";

const createTwitterLink = () => {
  const tweetText = `Get help voting https://www.beavoterwi.org`;
  return `https://twitter.com/intent/tweet?text=${encodeURI(tweetText)}`;
};

const createFacebookLink = () => {
  const url = `https://www.beavoterwi.org`;
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`;
};

export const ShareButton = ({ type, children }) => {
  const types = {
    facebook: createFacebookLink(),
    twitter: createTwitterLink(),
  };
  const icon = {
    facebook: RiFacebookCircleFill,
    twitter: RiTwitterFill,
  };
  return (
    <Flex
      as="a"
      href={types[type]}
      target="_blank"
      border="2px solid"
      borderColor="gray.300"
      alignItems="center"
      p={4}
      borderRadius={5}
      mb={5}
    >
      <Box
        as={icon[type]}
        target="_blank"
        mr={4}
        fontSize="2xl"
        color="gray.400"
      />
      <Text color="text">{children}</Text>
    </Flex>
  );
};

class CheckStatusService {
  constructor() {
    this.unregisteredMock = {
      Data: { $id: "1", voters: { $id: "2", $values: [] } },
      Success: true,
      ErrorMessage: null,
      WarningMessage: null,
    };
    this.registeredMock = {
      Data: {
        $id: "1",
        voters: {
          $id: "2",
          $values: [
            {
              $id: "3",
              voterID: "f670b596-c5b0-e511-80ea-0050568c2fc0",
              voterName: "Barrington, Matthew R",
              address: "N7670 Indian Hills Trl",
              voterStatus: 0,
              voterStatusName: "Active",
              statusReasonCode: 757580001,
              statusReasonName: "Registered",
              city: "Beaver Dam",
              postalCode: "53916-9314",
              state: "WI",
              dateOfBirth: "1985-08-13T00:00:00Z",
              districtComboID: "f50838ed-ddaf-e511-80ea-0050568c2fc0",
              jurisdictionID: "a9810861-ddaf-e511-80ea-0050568c2fc0",
              voterTypeID: 757580000,
              voterTypeName: "Regular",
              registrationDate: "10/20/2016",
              registrationSource: "Mail",
              voterRegNumber: "0018010671",
              congressionalCode: "CG-06",
            },
          ],
        },
      },
      Success: true,
      ErrorMessage: null,
      WarningMessage: null,
    };
  }

  buildFailureStatus(message) {
    if (!message) {
      message = "Unexpected Error";
    }
    return {
      Data: { $id: "1", voters: { $id: "2", $values: [] } },
      Success: false,
      ErrorMessage: message,
      WarningMessage: null,
    };
  }

  checkStatus(data) {
    //First look for special mock keywords
    if (data.firstName === "registered") {
      return Promise.resolve(this.registeredMock);
    }
    if (data.firstName === "unregistered") {
      return Promise.resolve(this.unregisteredMock);
    }

    //Hit the actual endpoint. This is a Lambda wrapper around the actual myvote.wi.gov api that
    // 1. strips whitespace, 2. retries failures with 01/01/1900, and 3. retries without non alphanumeric characters
    return fetch(
      "https://3105ahfheb.execute-api.us-east-1.amazonaws.com/Prod/checkRegistration",
      {
        method: "post",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .catch((e) => this.buildFailureStatus(e));
  }
}

export const checkStatusService = new CheckStatusService();

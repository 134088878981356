import React from "react";
import { Box } from "@chakra-ui/core";

export const Stripes = ({ flip }) => (
  <Box sx={{ transform: flip ? "scaleX(-1)" : "none" }}>
    <svg
      width="57px"
      height="124px"
      viewBox="0 0 57 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M-4 0H16L-4 44V0Z" fill="#C6534D" />
      <path
        d="M36.0101 0H56.0101L0.0100594 124H-20.95L36.0101 0Z"
        fill="#235888"
      />
    </svg>
  </Box>
);

class PollingPlaceService {
  constructor() {
    this.mockData = {
      Data: {
        $id: "1",
        address: {
          $id: "2",
          addressID: "00000000-0000-0000-0000-000000000000",
          addressStatus: {
            $id: "3",
            optionSetname: "Valid",
            optionSetValue: 757580000,
          },
          addressState: null,
          houseNumber: "301",
          streetName: "S WESTHAVEN DR",
          unitType: {
            $id: "4",
            optionSetname: "",
            optionSetValue: null,
          },
          aptNumber: "",
          city: "OSHKOSH",
          state: {
            $id: "5",
            optionSetname: "WI",
            optionSetValue: 757580000,
          },
          postalCode: "54904",
          isAddressExistInDb: true,
          isOutOfJurisdiction: false,
          primaryAddressLine: "301 S WESTHAVEN DR",
          secondaryAddressLine: "",
          fullAddressLine: "301 S WESTHAVEN DR , OSHKOSH, WI 54904",
          addressWithoutUnit: "301 S WESTHAVEN DR, OSHKOSH, WI 54904",
          fullAddressLine_WithOutSpace_Zp5: "301SWESTHAVENDR54904",
          validationMessages: {
            $id: "6",
            $values: [],
          },
          geoCode: {
            $id: "7",
            latitude: 44.21996,
            longitude: -86.966379,
            geoCodeSource: {
              $id: "8",
              optionSetname: null,
              optionSetValue: null,
            },
            geoCodeStatus: {
              $id: "9",
              optionSetname: "Invalid",
              optionSetValue: null,
            },
            score: null,
          },
          districtCombo: {
            $id: "10",
            districtComboId: "b9c136e1-ddaf-e511-80ea-0050568c2fc0",
            jurisdictionId: "00000000-0000-0000-0000-000000000000",
            jurisdictionName: null,
            districtComboCode: null,
            districtComboName: null,
            jurisdictionTypeName: null,
            countyName: null,
          },
          deliveryPointBarcode: null,
        },
        pollingPlace: {
          $id: "11",
          electionID: "d4a9c3eb-e577-e911-8106-0050568c2101",
          electionDate: "2020-11-03T06:00:00Z",
          electionDescription: "2020 General Election",
          startTime: "7.00 AM",
          endTime: "8.00 PM",
          pplid: "3b7b7b6e-03b0-e511-80ea-0050568c2fc0",
          pollingLocationName: "Calvary Lutheran Church",
          ppL_Address: "2580 W 9Th Ave",
          ppL_City: "Oshkosh",
          ppL_PostalCode: "54904-8125",
          latitude: "44.0113680000",
          longitude: "-88.5971480000",
          wardName: "City Of Oshkosh - Ward 21",
        },
        clerk: {
          $id: "12",
          jurisdictionID: "9664f172-ddaf-e511-80ea-0050568c2fc0",
          jurisdictionName: "City Of Oshkosh - Winnebago County",
          clerkName: "Pamela Ubrig",
          muncipalAddress: "215 Church Ave, Oshkosh, Wi 54901-4747",
          mailingAddress: "215 Church Ave, Oshkosh, Wi 54901-4747",
          phone1: "(920)236-5011",
          phone2: "",
          fax: "(920)236-5011",
          email: "PUBRIG@CI.OSHKOSH.WI.US",
          notificationEmail: "PUBRIG@CI.OSHKOSH.WI.US",
          sendEmailtoClerk: true,
          municipalityWebsite: "http://www.ci.oshkosh.wi.us",
        },
        sampleBallot: {
          // ...
        },
        officeHolders: {
          $id: "54",
          // ...
        },
        Success: true,
        ErrorMessage: null,
        WarningMessage: null,
      },
    };
  }

  buildFailureStatus(message) {
    if (!message) {
      message = "Unexpected Error";
    }
    return {
      Data: { $id: "1", voters: { $id: "2", $values: [] } },
      Success: false,
      ErrorMessage: message,
      WarningMessage: null,
    };
  }

  findPlaces(data) {
    //First look for special mock values
    if (data.addressLine === "641 West Lake Street") {
      return Promise.resolve(this.mockData);
    }

    if (!data.unit) {
      data.unit = ""
    }

    //Hit the actual endpoint. This is a Lambda wrapper around the actual myvote.wi.gov api
    return fetch(
      "https://4rhb9e1m5j.execute-api.us-east-1.amazonaws.com/Prod/findPollingSites",
      {
        method: "post",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .catch((e) => this.buildFailureStatus(e));
  }
}

export const pollingPlaceService = new PollingPlaceService();

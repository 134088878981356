import React from "react";
import { Heading, Text, Link } from "@chakra-ui/core";
import { Layout } from "../components/Layout";
import { Divider } from "../components/Divider";
import { useApp } from "../contexts/app.context";
import { Redirect } from "react-router";
import { format } from "date-fns";
import { GoogleMapsLink } from "../components/GoogleMapsLink";
import { Rideshare } from "../components/Rideshare";

export const Plan4LateResponse = () => {
  const { pollingPlace, voterAddress } = useApp();
  if (!pollingPlace) return <Redirect to="/more-help" />;
    return (
    <Layout>
      <Heading size="xl" mb={6} color="red">
        Here is{" "}
        <Text as="span" color="blue">
          your{" "}
        </Text>
        voting plan.
      </Heading>
      <Text mb={3}>
        Vote in person on{" "}
        <Text as="span" fontWeight="bold">
          Election Day
        </Text>
        , November 3, 2020.
      </Text>
      <Text mb={3}>
        You likely do not have enough time to request or return a vote-by-mail ballot
        before the deadline. We recommend that you vote in person on Election
        Day.
      </Text>
      <Text mb={3}>
        If you already have a vote-by-mail ballot, please tell the election
        worker that you are abandoning that ballot to vote in person.
      </Text>
      <Divider />
      <Text mb={3}>Your polling place is:</Text>
      <Text>{pollingPlace.pollingLocationName}</Text>
      <GoogleMapsLink
        address={pollingPlace.ppL_Address}
        city={pollingPlace.ppL_City}
        zip={pollingPlace.ppL_PostalCode}
      >
        <Text>{pollingPlace.ppL_Address}</Text>
        <Text>
          {pollingPlace.ppL_City}, {pollingPlace.ppL_PostalCode}
        </Text>
      </GoogleMapsLink>
      <Divider mt={4}/>
      <Text mb={3}>The locations hours are:</Text>
      <Text>{format(new Date(pollingPlace.electionDate), "PPP")}</Text>
      <Text>
        {pollingPlace.startTime} - {pollingPlace.endTime}
      </Text>
      <Divider mt={4} />
        <Text>
            Remember to bring a photo ID.{" "}
            <Link
                href="https://myvote.wi.gov/en-us/PhotoIDRequired"
                target="_blank"
                color="active"
                textDecoration="underline"
            >
                See which ones are valid
            </Link>
            .
        </Text>
        <Text>
            If you are registering, you need to bring a{" "}
            <Link
                href="https://myvote.wi.gov/en-us/ProofofResidence"
                target="_blank"
                color="active"
                textDecoration="underline"
            >
                proof of residence.{" "}
            </Link>
        </Text>
      <Rideshare />
        <Text> <Text as="span" fontWeight="bold">Curbside Voting:</Text> You can use curbside voting at your polling location on election day or early vote site.  It is available to any voter who is immunocompromised, was exposed to or has symptoms of COVID-19, as well as voters who have a disability.
        </Text>
        <Text mt={3}>
            Call your clerk (<Link target="_blank"
                                   color="active"
                                   textDecoration="underline" href="https://myvote.wi.gov/en-US/MyMunicipalClerk">find your clerk here</Link>) ahead of your trip to arrange or have someone with you go inside the polling place to request curbside assistance.
          Bring your ID, (proof of residence if registering), a pen, and mask up.
      </Text>
    </Layout>
  );
};

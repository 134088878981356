
class IWVBallotOptionsService {
  constructor() {
    this.mockData = {}
  }

  buildFailureStatus(message) {
    if (!message) {
      message = "Unexpected Error";
    }
    return {
      Success: false,
      ErrorMessage: message,
    };
  }

  findBallotOptions(street, zip) {

    let address = street + ", " + zip;

    let url = new URL("https://4rhb9e1m5j.execute-api.us-east-1.amazonaws.com/Prod/findBallotOptions")
    url.searchParams.append("address", address)
    console.log("about to fetch " + url);

    return fetch(url)
      .then((response) => response.json())
      .catch((e) => this.buildFailureStatus(e))
        .then(response => {
          if (response.dropbox_locations) {
            for (const dropbox of response.dropbox_locations) {
              dropbox.title = dropbox.location_name
            }
          }
          if (response.early_vote_locations) {
            for (const early of response.early_vote_locations) {
              early.title = early.location_name
            }
          }
          return response;
        });
  }
}

export const iwvBallotOptionsService = new IWVBallotOptionsService();

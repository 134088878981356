class CheckBallotStatusService {
  constructor() {
    this.acceptedMock = {
      Data: {
        dateBallotReturned: "2020-10-09",
        },
      Success: true,
      ErrorMessage: null,
      WarningMessage: null,
    };
    this.pendingMock = {
      Data: {
        absenteeRequestSubmittedDate: "2020-10-09"
      },
      Success: true,
      ErrorMessage: null,
      WarningMessage: null,
    };
    this.rejectedMock = {
      Data: {
        dateBallotReturned: "2020-10-09",
        ballotReturnedRejectedReason: 1
      },
      Success: true,
      ErrorMessage: null,
      WarningMessage: null,
    };
    this.norequestMock = {
      Data: {
      },
      Success: true,
      ErrorMessage: null,
      WarningMessage: null,
    };
  }

  buildFailureStatus(message) {
    if (!message) {
      message = "Unexpected Error";
    }
    return {
      Data: { $id: "1", voters: { $id: "2", $values: [] } },
      Success: false,
      ErrorMessage: message,
      WarningMessage: null,
    };
  }

  checkStatus(voterID, lastName) {
    //First look for special mock keywords in last name
    if (lastName === "accepted") {
      return Promise.resolve(this.acceptedMock);
    }
    if (lastName === "rejected") {
      return Promise.resolve(this.rejectedMock);
    }
    if (lastName === "pending") {
      return Promise.resolve(this.pendingMock);
    }
    if (lastName === "norequest") {
      return Promise.resolve(this.norequestMock);
    }

    let url =
      "https://4rhb9e1m5j.execute-api.us-east-1.amazonaws.com/Prod/checkBallotStatus?voterID=" +
      voterID;
    console.log("about to fetch " + url);

    return fetch(url)
      .then((response) => response.json())
      .catch((e) => this.buildFailureStatus(e));
  }
}

export const checkBallotStatusService = new CheckBallotStatusService();
